import { type Location } from "react-router-dom"
import { type ILocations } from "../components/Terminals/TerminalsTypesFlattened"
import { type StoreDetails } from "../types/detailsTypes"

interface Option {
  label: string
  action: () => void
}

export const getOptions = (
  handleClose: (storeItem?: any) => void,
  navigate: (path: string) => void,
  businessId: string,
  businessName: string,
  locationId: string,
  locationName: string,
  location: Location,
  newStoreItem?: ILocations | StoreDetails | null,
  terminalName: string | null = null,
  terminalId: string | null = null
): Option[] => {
  // Handle the case when business is clicked
  if (locationId === null) {
    let options = [
      {
        label: "Details",
        action: () => {
          navigate(`/business-details?businessId=${encodeURIComponent(businessId)}`)
          console.log("businessId inside menuOptions:", businessId)
          newStoreItem !== null && newStoreItem !== undefined
            ? handleClose(newStoreItem)
            : handleClose()
        },
      },
      { label: "Revenue", action: handleClose },
    ]
    // Include "Terminals" option only when not on terminals page
    if (!location.pathname.startsWith("/terminals")) {
      // placing the "Terminals" option at index 2 to be consistent with the order of the options in the UI
      options.push({
        label: "Terminals",
        action: handleClose,
      })
    }
    // part of disable Support
    // options.push({
    //   label: "Support",
    //   action: () => {
    //     navigate(
    //       `/support?businessId=${businessId}&businessName=${encodeURIComponent(businessName)}`
    //     )
    //     newStoreItem !== null && newStoreItem !== undefined
    //       ? handleClose(newStoreItem)
    //       : handleClose()
    //   },
    // })
    // Exclude "Details" option when on "/business-details" page
    if (location.pathname.startsWith("/business-details")) {
      options = options.filter((option) => option.label !== "Details")
    }

    return options
  }

  // Handle the case when a terminal is clicked
  if (terminalId !== null && terminalName !== null) {
    return [
      {
        label: "Details",
        action: () => {
          navigate(
            `/terminal-details?terminalId=${terminalId}&locationId=${locationId}&businessId=${businessId}`
          )
          newStoreItem !== null && newStoreItem !== undefined
            ? handleClose(newStoreItem)
            : handleClose()
        },
      },
      // part of disable Support
      // {
      //   label: "Support",
      //   action: () => {
      //     const supportUrl = `/support?locationId=${locationId}&locationName=${encodeURIComponent(
      //       locationName
      //     )}&businessId=${businessId}&businessName=${encodeURIComponent(
      //       businessName
      //     )}&terminal=${terminalName}`
      //     navigate(supportUrl)
      //     newStoreItem !== null && newStoreItem !== undefined
      //       ? handleClose(newStoreItem)
      //       : handleClose()
      //   },
      // },
    ]
  }

  // Handle the case when a location is clicked from terminals page
  // If on terminals page, don't include the "Terminals" option
  if (location.pathname.startsWith("/terminals")) {
    return [
      {
        label: "Details",
        action: () => {
          navigate(`/business-details?businessId=${businessId}`)
          newStoreItem !== null && newStoreItem !== undefined
            ? handleClose(newStoreItem)
            : handleClose()
        },
      },
      {
        label: "Revenue",
        action: () => {
          navigate(
            `/reports/revenue?locationId=${locationId}&locationName=${encodeURIComponent(
              locationName
            )}&businessId=${encodeURIComponent(businessId)}`
          )
          newStoreItem !== null && newStoreItem !== undefined
            ? handleClose(newStoreItem)
            : handleClose()
        },
      },
      // part of disable Support
      // {
      //   label: "Support",
      //   action: () => {
      //     let supportUrl = `/support?locationId=${locationId}&locationName=${encodeURIComponent(
      //       locationName
      //     )}&businessId=${businessId}&businessName=${encodeURIComponent(businessName)}`
      //     if (terminalName !== undefined && terminalName !== null) {
      //       supportUrl += `&terminal=${terminalName}`
      //     }
      //     navigate(supportUrl)
      //     newStoreItem !== null && newStoreItem !== undefined
      //       ? handleClose(newStoreItem)
      //       : handleClose()
      //   },
      // },
    ]
  }

  if (location.pathname.startsWith("/business-details")) {
    return [
      {
        label: "Revenue",
        action: () => {
          const url = `/reports/revenue?locationId=${locationId}&locationName=${encodeURIComponent(
            locationName
          )}&businessId=${encodeURIComponent(businessId)}`
          navigate(url)
          newStoreItem !== null && newStoreItem !== undefined
            ? handleClose(newStoreItem)
            : handleClose()
        },
      },
      {
        label: "Terminals",
        action: () => {
          navigate(
            `/terminals?locationId=${locationId}&locationName=${encodeURIComponent(
              locationName
            )}&businessId=${encodeURIComponent(businessId)}`
          )
          newStoreItem !== null && newStoreItem !== undefined
            ? handleClose(newStoreItem)
            : handleClose()
        },
      },
      // part of disable Support
      // {
      //   label: "Support",
      //   action: () => {
      //     let supportUrl = `/support?locationId=${locationId}&locationName=${encodeURIComponent(
      //       locationName
      //     )}&businessId=${businessId}&businessName=${encodeURIComponent(businessName)}`
      //     if (terminalName !== undefined && terminalName !== null) {
      //       supportUrl += `&terminal=${terminalName}`
      //     }
      //     navigate(supportUrl)
      //     newStoreItem !== null && newStoreItem !== undefined
      //       ? handleClose(newStoreItem)
      //       : handleClose()
      //   },
      // },
    ]
  }

  return [
    {
      label: "Details",
      action: () => {
        navigate(`/business-details?businessId=${businessId}`)
        newStoreItem !== null && newStoreItem !== undefined
          ? handleClose(newStoreItem)
          : handleClose()
      },
    },
    {
      label: "Revenue",
      action: () => {
        navigate(
          `/reports/revenue?locationId=${locationId}&locationName=${encodeURIComponent(
            locationName
          )}&businessId=${encodeURIComponent(businessId)}`
        )
        newStoreItem !== null && newStoreItem !== undefined
          ? handleClose(newStoreItem)
          : handleClose()
      },
    },
    {
      label: "Terminals",
      action: () => {
        navigate(
          `/terminals?locationId=${locationId}&locationName=${encodeURIComponent(
            locationName
          )}&businessId=${encodeURIComponent(businessId)}`
        )
        newStoreItem !== null && newStoreItem !== undefined
          ? handleClose(newStoreItem)
          : handleClose()
      },
    },
    // part of disable Support
    // {
    //   label: "Support",
    //   action: () => {
    //     let supportUrl = `/support?locationId=${locationId}&locationName=${encodeURIComponent(
    //       locationName
    //     )}&businessId=${businessId}&businessName=${encodeURIComponent(businessName)}`
    //     if (terminalName !== undefined && terminalName !== null) {
    //       supportUrl += `&terminal=${terminalName}`
    //     }
    //     navigate(supportUrl)
    //     newStoreItem !== null && newStoreItem !== undefined
    //       ? handleClose(newStoreItem)
    //       : handleClose()
    //   },
    // },
  ]
}
