import React from "react"
import LicenseSupportHeader from "./LicenseSupportHeader"
import LicenseSupportInputs from "./LicenseSupportInputs"
import LicenseSupportSubmit from "./LicenseSupportSubmit"

const LicenseSupportForm: React.FC = () => {
  return (
    <div>
      <LicenseSupportHeader />
      <LicenseSupportInputs />
      <LicenseSupportSubmit />
    </div>
  )
}

export default LicenseSupportForm
