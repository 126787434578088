import React from "react"
import { Alert, Snackbar, Typography } from "@mui/material"

export interface NotificationAlertProps {
  open: boolean
  severity: "error" | "warning" | "info" | "success"
  message: string
  onClose: () => void
  autoHideDuration?: number
}

const NotificationAlert: React.FC<NotificationAlertProps> = ({
  open,
  severity,
  message,
  onClose,
  autoHideDuration = 6000,
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={(event, reason) => {
        if (reason !== "clickaway") {
          onClose()
        }
      }}
      sx={{ position: "static" }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ bottom: 0, mt: 2, width: "fill-available" }}
      >
        <Typography variant="body1" fontWeight={700}>
          Unable to complete request:
        </Typography>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default NotificationAlert
