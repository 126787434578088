import React from "react"
import { TextField } from "@mui/material"

interface ReasonInputProps {
  reason: string
  onChange: (value: string) => void
  disabled: boolean
}

const ReasonInput: React.FC<ReasonInputProps> = ({ reason, onChange, disabled }) => {
  return (
    <TextField
      required
      fullWidth
      multiline
      value={reason}
      label="Reason"
      placeholder="Enter the reason"
      onChange={(e) => {
        onChange(e.target.value)
      }}
      disabled={disabled}
      variant="outlined"
      inputProps={{ maxLength: 100 }}
      helperText={`${reason.length}/100`}
      FormHelperTextProps={{
        sx: { textAlign: "right", m: 0, pr: 1 },
      }}
      InputLabelProps={{
        shrink: true,
        sx: {
          "& .MuiInputLabel-asterisk": {
            color: "red",
          },
        },
      }}
    />
  )
}

export default ReasonInput
