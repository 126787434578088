import { type RouteObject, useRoutes } from "react-router-dom"
import {
  Home,
  Overview,
  Terminals,
  Login,
  Reports,
  CashAdjustments,
  Performance,
  Error,
  Revenue,
  Period,
  Sales,
  Collections,
  CurrentCash,
  // part of disable Support
  // Support,
  AccountSettings,
  BusinessDetails,
  TerminalDetailsPage,
  Licensing,
  PurchaseFills,
  PurchaseHistory,
  FillCodeLookup,
  Recover,
  ResetPassword,
  News,
} from "../pages"
import NotFound from "../pages/NotFound"
import { AuthLayout } from "../layouts/AuthLayout"
import { SidebarLayout } from "../layouts/SidebarLayout"
import { FiltersLayout } from "../layouts/FiltersLayout"
import { type ComponentType } from "react"
import { PaymentStatus } from "../pages/Licensing/PaymentStatus"
import { OrderSummary } from "../pages/Licensing/OrderSummary/Index"
import { EncryptStorage } from "encrypt-storage"
import { LicenseSupport } from "../pages/Licensing/LicenseSupport"

interface ComponentProps {
  openFiltersHandler?: () => void
}

const layoutRouter = (
  Component: ComponentType<ComponentProps>,
  path: string,
  hasFilters = false
): RouteObject => {
  return {
    path,
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: (
          <SidebarLayout>
            {hasFilters ? <FiltersLayout Component={Component} /> : <Component />}
          </SidebarLayout>
        ),
      },
    ],
  }
}

export function RoutesContainer() {
  // Read licensing flags from localStorage.
  const storedLicensing = localStorage.getItem("licensing")
  const showLicensing: boolean =
    storedLicensing != null ? JSON.parse(storedLicensing) === true : false

  const storedLicensingSupport = localStorage.getItem("licensingSupport")
  const showLicensingSupport: boolean =
    storedLicensingSupport != null ? JSON.parse(storedLicensingSupport) === true : false

  const storage = new EncryptStorage("jfkejfklrjklgjerlk485859809gjtkljkgjl")
  const locations = storage.getItem("locations")

  // If the contact has only Got Skill locations, we don't show the news page.
  const hasGotSkill: boolean = locations?.every(
    (location: any) => location.productType === "Got Skill"
  )

  // Define licensing routes.
  // All licensing routes are added if licensing is true.
  // However, the License Support route is only included if licensingSupport is true.
  const licensingRoutes: RouteObject[] = [
    layoutRouter(Licensing, "/licensing"),
    layoutRouter(PurchaseFills, "/licensing/purchase-fills"),
    layoutRouter(OrderSummary, "/licensing/order-summary"),
    layoutRouter(PurchaseHistory, "/licensing/purchase-history"),
    layoutRouter(FillCodeLookup, "/licensing/fill-lookup"),
    layoutRouter(PaymentStatus, "/licensing/payment-status"),
    ...(showLicensingSupport ? [layoutRouter(LicenseSupport, "/licensing/license-support")] : []),
  ]

  const element = useRoutes([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/recover",
      element: <Recover />,
    },
    {
      path: "/reset",
      element: <ResetPassword />,
    },
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        {
          path: "",
          element: <Home />,
        },
      ],
    },

    layoutRouter(Reports, "/reports"),

    layoutRouter(Overview, "/overview", true),

    layoutRouter(BusinessDetails, "/business-details"),

    layoutRouter(TerminalDetailsPage, "/terminal-details"),

    layoutRouter(Terminals, "/terminals", true),

    // Append licensing routes only if licensing is enabled.
    ...(showLicensing ? licensingRoutes : []),

    layoutRouter(CashAdjustments, "/reports/cash-adjustments", true),

    layoutRouter(Performance, "/reports/performance", true),

    layoutRouter(Revenue, "/reports/revenue", true),

    layoutRouter(Period, "/reports/period", true),

    layoutRouter(Sales, "/reports/sales", true),

    layoutRouter(Collections, "/reports/collections", true),

    layoutRouter(CurrentCash, "/reports/current-cash", true),

    // part of disable Support
    // layoutRouter(Support, "/support"),

    layoutRouter(AccountSettings, "/settings"),

    // Include News route only if there is no location with "Got Skill" product.
    ...(!hasGotSkill ? [layoutRouter(News, "/news")] : []),

    // Catch-all routes.
    {
      path: "/error",
      element: <Error />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ])

  return element
}
