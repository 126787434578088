import { Box, Typography } from "@mui/material"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { getPaymentMethodColor } from "../utils/getPaymentMethodColor"
import { formatAmount } from "../utils/formatAmount"

interface PurchaseMethodProps {
  purchaseMethod: string
  reason: string
  purchaseTotal: number
}

export const PurchaseMethod = ({ purchaseMethod, reason, purchaseTotal }: PurchaseMethodProps) => {
  const { color } = getPaymentMethodColor(purchaseMethod)

  const renderIcon = () => {
    switch (purchaseMethod?.toLowerCase()) {
      case "tech support":
        return <HelpOutlineIcon className="mr-1" fontSize="small" sx={{ color }} />
      case "invoice":
        return <ReceiptOutlinedIcon className="mr-1" fontSize="small" sx={{ color }} />
      default:
        return <CreditCardIcon className="mr-1" fontSize="small" sx={{ color }} />
    }
  }

  const getPurchaseMethodLabel = (method?: string): string => {
    if (method == null) return "Paid"
    switch (method.toLowerCase()) {
      case "invoice":
        return "Invoiced"
      case "tech support":
        return "Tech Support"
      default:
        return "Paid"
    }
  }

  return (
    <Box>
      <Typography sx={{ display: "flex", alignItems: "center" }} variant="body2">
        {renderIcon()}
        {`${getPurchaseMethodLabel(purchaseMethod)}: ${formatAmount(purchaseTotal).full}`}
      </Typography>
      {purchaseMethod?.toLowerCase() === "tech support" &&
        reason.length > 0 &&
        reason.trim() !== "" && (
          <Typography
            variant="caption"
            sx={{
              ml: 3,
              display: "block",
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {reason}
          </Typography>
        )}
    </Box>
  )
}
