import { useCallback, useState } from "react"
import {
  useAddItemToCartMutation,
  useDecodeRequestCodeMutation,
} from "../redux/business/business.api"
import { type CartItem } from "../types/cartItemTypes"
import { formatLicensingErrorMessages } from "../utils/formatLicensingErrorMessages"

const useDecodeRequest = (
  addMachine: (machines: CartItem[], replace: boolean) => void,
  refetchCartItemData: () => void
) => {
  const [decodeCode] = useDecodeRequestCodeMutation({})
  const [addItemToCart] = useAddItemToCartMutation({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [showCodeInput, setShowCodeInput] = useState(false)
  const [newMachineCode, setNewMachineCode] = useState("")
  const [codeParam, setCodeParam] = useState<string | null>(null)

  const clearUrlParam = () => {
    const url = new URL(window.location.href)
    url.searchParams.delete("c")
    window.history.pushState({}, "", url.href)
  }

  const handleScannedData = useCallback((data: string) => {
    setNewMachineCode(data)
  }, [])

  const handleDecodeRequest = async (newMachineCode: string, code?: string) => {
    setIsSubmitting(true)
    try {
      // Use passed code if provided; otherwise, use newMachineCode.
      let codeToDecode = code ?? newMachineCode
      if (codeToDecode.trim() === "") return

      // If the scanned code is a full URL, extract the 'c' query parameter.
      if (codeToDecode.includes("http") || codeToDecode.includes("https")) {
        const url = new URL(codeToDecode)
        const extracted = url.searchParams.get("c")
        if (extracted != null && extracted.trim() !== "") {
          codeToDecode = extracted
        }
      }

      // Now call your decode API with the extracted fill request code.
      const response = await decodeCode({ fillRequestCode: codeToDecode }).unwrap()

      if (response.rc === 2011 || response.rc === 2015 || response.rc === 2021) {
        const messages = formatLicensingErrorMessages(response)
        messages.forEach((msg) => {
          console.error("Decode API error:", msg)
        })
        throw new Error(messages.join("; "))
      }

      if (response.rc === 1000) {
        const { decodeData } = response.data
        if (decodeData != null) {
          const newMachine: CartItem = { machineId: decodeData.machineId }

          const addItemRes = await addItemToCart({
            machineId: newMachine.machineId,
            requestCode: codeToDecode,
          }).unwrap()

          if (addItemRes.rc === 2013 || addItemRes.rc === 2015 || addItemRes.rc === 2021) {
            const messages = formatLicensingErrorMessages(addItemRes)
            messages.forEach((msg) => {
              console.error("Add API error:", msg)
            })
            throw new Error(messages.join("; "))
          } else if (addItemRes.rc === 1000) {
            newMachine.cartItemId = addItemRes.data.cartItemId
            addMachine([newMachine], false)
            // Close the dialog only on success.
            setShowCodeInput(false)
          } else {
            console.error("Unexpected response from addItemToCart:", addItemRes)
          }
          refetchCartItemData()
        } else {
          console.error("Invalid decode response data:", response)
        }
      }
    } catch (err: any) {
      let messages: string[] = []
      if (err?.data != null) {
        messages = formatLicensingErrorMessages(err.data)
      } else if (err instanceof Error) {
        messages = [err.message]
      } else {
        messages = ["An unexpected error occurred during the decode process."]
      }
      messages.forEach((msg) => {
        console.error("Error in handleDecodeRequest:", msg)
      })
      throw new Error(messages.join("; "))
    } finally {
      setIsSubmitting(false)
      sessionStorage.removeItem("cParam")
      clearUrlParam()
    }
  }

  return {
    handleDecodeRequest,
    isSubmitting,
    showCodeInput,
    setShowCodeInput,
    newMachineCode,
    setNewMachineCode,
    codeParam,
    setCodeParam,
    handleScannedData,
  }
}

export default useDecodeRequest
