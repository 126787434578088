import React from "react"
import { Button, Alert } from "@mui/material"
import KeyIcon from "@mui/icons-material/Key"
import ReplayIcon from "@mui/icons-material/Replay"
import { useLicenseSupportContext } from "../../../context/LicenseSupportContext"

const LicenseSupportSubmit: React.FC = () => {
  const {
    reason,
    error,
    isSubmitting,
    selectedFill,
    openIssueConfirmDialog,
    openReissueConfirmDialog,
  } = useLicenseSupportContext()

  const isFillEmpty = selectedFill.amount.trim().length === 0
  const isDisabled = isSubmitting || isFillEmpty || reason.trim().length === 0

  return (
    <div className="p-2 rounded mt-4 border border-gray-200">
      <div className="flex flex-wrap justify-end gap-2">
        <Button
          startIcon={<KeyIcon />}
          variant="contained"
          sx={{
            width: { xs: "100%", sm: "auto" },
            backgroundColor: "rgba(252, 163, 17, 1)",
            color: "white",
            height: "40px",
            "&:hover": { backgroundColor: "rgba(252, 163, 17, 0.6)" },
            "&:active": { backgroundColor: "rgba(252, 163, 17, 0.6)" },
            "&.Mui-disabled": { backgroundColor: "rgba(252, 163, 17, 0.3)" },
          }}
          disabled={isDisabled}
          onClick={openIssueConfirmDialog}
        >
          Issue New Code
        </Button>
        <Button
          startIcon={<ReplayIcon />}
          variant="contained"
          sx={{
            width: { xs: "100%", sm: "auto" },
            backgroundColor: "rgba(255, 0, 0, 0.8)",
            color: "white",
            height: "40px",
            "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.6)" },
            "&:active": { backgroundColor: "rgba(255, 0, 0, 0.6)" },
            "&.Mui-disabled": { backgroundColor: "rgba(255, 0, 0, 0.3)" },
          }}
          disabled={isDisabled}
          onClick={openReissueConfirmDialog}
        >
          Re-Issue Same Code
        </Button>
      </div>
      {error.length > 0 && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </div>
  )
}

export default LicenseSupportSubmit
