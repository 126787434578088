import toast from "react-hot-toast"
import type { HandleErrorType } from "../types/reportTypes"
import { navigateTo } from "../services/NavigationService"

const handle401Error: HandleErrorType = () => {
  // localStorage.clear()
  localStorage.removeItem("token")
  localStorage.removeItem("firstName")
  localStorage.removeItem("lastName")
  localStorage.removeItem("locations")
  localStorage.removeItem("licensing")
  localStorage.removeItem("licensingSupport")
  navigateTo("/login")
  toast.dismiss()
  toast.error("Session has expired, please login again.")
}

const handle400Error: HandleErrorType = () => {
  toast.dismiss()
  toast.error("An error occurred while fetching the report.")
}

const handle524Error: HandleErrorType = () => {
  if (!navigator.onLine) {
    toast.dismiss()
    toast.error("The server took too long to respond. Please try again later.")
  } else {
    toast.dismiss()
    toast.error("Server Timeout")
  }
}

const handleCORSError: HandleErrorType = () => {
  toast.dismiss()
  toast.error("Failed to connect with the server.")
}

export const handleError: HandleErrorType = (error) => {
  console.log("ERROR FROM HANDLE ERROR", error?.status)

  if (error?.status === "FETCH_ERROR" && error.error === "TypeError: Failed to fetch") {
    // This is a common symptom of a CORS error or a network failure
    handleCORSError(error)
    return
  }

  if (error?.status === 400) {
    handle400Error(error)
    return
  }
  if (error?.status === 401) {
    handle401Error(error)
    return
  }
  if (error?.status === 524) {
    handle524Error(error)
  }
}

export const showToastForResponseCode = (rc: number, message?: string) => {
  // Directly use the backend message if available
  if (message !== null && message !== undefined && message !== "Ok") {
    toast.dismiss()
    toast.error(message)
  } else {
    // Fallback or additional handling could go here if needed
    console.log(`Unhandled response code: ${rc}`)
  }
}
