import EvStationOutlinedIcon from "@mui/icons-material/EvStationOutlined"
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined"
import ScreenSearchDesktopOutlinedIcon from "@mui/icons-material/ScreenSearchDesktopOutlined"
import ContactSupportIcon from "@mui/icons-material/ContactSupport"

export interface LicensingButtonType {
  title: string
  subTitle: string
  icon: React.ElementType
  href: string
}

export const getLicensingButtons = (): LicensingButtonType[] => {
  const baseButtons: LicensingButtonType[] = [
    {
      title: "Purchase Fills",
      subTitle: "",
      icon: EvStationOutlinedIcon,
      href: "/licensing/purchase-fills",
    },
    {
      title: "Purchase History",
      subTitle: "",
      icon: CurrencyExchangeOutlinedIcon,
      href: "/licensing/purchase-history",
    },
    {
      title: "Fill Code Lookup",
      subTitle: "",
      icon: ScreenSearchDesktopOutlinedIcon,
      href: "/licensing/fill-lookup",
    },
  ]

  // Safely read from localStorage (with SSR guard)
  const storedLicensingSupport =
    typeof window !== "undefined" ? localStorage.getItem("licensingSupport") : null
  // Parse it or fallback to false
  const isLicensingSupportEnabled =
    storedLicensingSupport != null ? JSON.parse(storedLicensingSupport) === true : false

  return [
    ...baseButtons,
    ...(isLicensingSupportEnabled
      ? [
          {
            title: "License Support",
            subTitle: "",
            icon: ContactSupportIcon,
            href: "/licensing/license-support",
          },
        ]
      : []),
  ]
}
