// External Library Imports
import { useState, memo, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import IconButton from "@mui/material/IconButton"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import PhoneIcon from "@mui/icons-material/Phone"
import CardHeader from "@mui/material/CardHeader"
import Box from "@mui/material/Box"
import ListItem from "@mui/material/ListItem"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"

// Absolute Imports
import { StatusCircleIcon } from "../StatusCircleIcon"
import { SelectableTypography } from "../SelectableTypography"
import { ThreeSixtyMenu } from "../ThreeSixtyMenu"
import {
  CardContentStyled,
  ListItemTextStyled,
  CardStyled,
  ListStyled,
} from "../../styles/OverviewCardStyles"
import type { RootState } from "../../redux/store"

// Utility functions
import { formatPhoneNumber } from "../../utils/formatPhoneNumber"
import { getAddressString } from "../../utils/getAddressString"
import { getOptions } from "../../data/menuOptions"

// Type imports for TypeScript
import type { Business, Location } from "./OverviewTypesFlattened"
import type { IBusinessInfo } from "../../types/businessDetailsTypes"

// Relative Imports (assets, etc.)
import terminalIcon from "../../assets/terminals/terminal-icon.svg"
import terminalIconRed from "../../assets/terminals/terminal-icon-red.svg"

interface BusinessCardProps {
  business: Business
}

const OverviewItemComponent = ({ business }: BusinessCardProps) => {
  const [locallyExpanded, setLocallyExpanded] = useState<Record<string, boolean>>({})
  const [manuallyClosed, setManuallyClosed] = useState<Record<string, boolean>>({})
  const [selectedStores, setSelectedStores] = useState<Record<string, Location>>({})
  const selectedStore = selectedStores[business.businessInfo.businessId]
  const { expandAll } = useSelector((state: RootState) => state.buttons)
  const [anchorEls, setAnchorEls] = useState<Record<string, HTMLElement | null>>({})

  const businessId360 = useRef<string>("")
  const businessName360 = useRef<string>("")
  const locationId360 = useRef<string>("")
  const locationName360 = useRef<string>("")

  const navigate = useNavigate()
  const location = useLocation()

  const handleClose = (storeId: string) => {
    setAnchorEls({ ...anchorEls, [storeId]: null })
  }

  const handleBussinesClick = (event: React.MouseEvent<HTMLButtonElement>, business: Business) => {
    localStorage.setItem("option", "false")
    businessId360.current = business.businessInfo.businessId
    businessName360.current = business.businessInfo.name
    setAnchorEls({ ...anchorEls, [businessId360.current]: event.currentTarget })
  }

  const handleStoreClick = (event: React.MouseEvent<HTMLButtonElement>, storeItem: Location) => {
    // localStorage.setItem("option", "true")
    locationId360.current = storeItem.locationId
    locationName360.current = storeItem.locationName
    setAnchorEls({ ...anchorEls, [locationId360.current]: event.currentTarget })
  }

  const isExpanded = expandAll
    ? !(manuallyClosed[business.businessInfo.businessId] ?? false)
    : locallyExpanded[business.businessInfo.businessId] ?? false

  const handleCloseBusiness = () => {
    handleClose(business.businessInfo.businessId)
  }
  const businessOptions = getOptions(
    handleCloseBusiness,
    navigate,
    businessId360.current,
    businessName360.current,
    locationId360.current,
    locationName360.current,
    location
  )

  useEffect(() => {
    if (!expandAll) {
      setManuallyClosed({})
    } else {
      setLocallyExpanded({})
    }
  }, [expandAll])

  useEffect(() => {
    if (!isExpanded) {
      setSelectedStores({})
    }
  }, [isExpanded])

  const isAnyStoreOffline: boolean = business.locations.some(
    (location) => location.locationStatus === "offline" || location.terminalAlert
  )

  return (
    <CardStyled variant="outlined">
      <CardHeader
        sx={{
          marginX: 0,
          marginBottom: 0,
          paddingX: 1,
          paddingY: { xs: 0.5, sm: 1 },
          borderRadius: "1px",
          backgroundColor: isAnyStoreOffline ? "#e5b8bb" : "#DEE2E6",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        title={business.businessInfo.name}
        subheader={
          <Box
            sx={{
              color: "#000c",
              marginTop: 1,
              display: "grid",
              gridTemplateColumns: "18px 1fr",
              rowGap: 0.5,
              columnGap: 1,
              alignItems: "flex-start",
            }}
          >
            <>
              <LocationOnIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
              <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                {getAddressString(business.businessInfo as IBusinessInfo)}
              </Typography>
            </>
            <>
              <PhoneIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
              <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                {business.businessInfo.phone !== ""
                  ? `Phone 1: ${formatPhoneNumber(business.businessInfo.phone)}`
                  : "No primary phone"}
              </Typography>
            </>
            <>
              <PhoneIcon sx={{ fontSize: 18, gridColumnStart: 1 }} />
              <Typography variant="body2" sx={{ gridColumnStart: 2 }}>
                {business.businessInfo.secondaryPhone !== ""
                  ? `Phone 2: ${formatPhoneNumber(business.businessInfo.secondaryPhone)}`
                  : "No secondary phone"}
              </Typography>
            </>
          </Box>
        }
        action={
          <>
            <IconButton
              onClick={(event) => {
                handleBussinesClick(event, business)
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <ThreeSixtyMenu
              id={`business-menu-${business.businessInfo.businessId}`}
              anchorEl={anchorEls[business.businessInfo.businessId]}
              onClose={() => {
                handleClose(business.businessInfo.businessId)
              }}
              options={businessOptions}
            />
          </>
        }
      />

      <CardContentStyled>
        <ListStyled>
          {business.locations.map((storeItem: Location) => {
            const handleCloseStore = () => {
              handleClose(storeItem.locationId)
            }
            const storeOptions = getOptions(
              handleCloseStore,
              navigate,
              (businessId360.current = business.businessInfo.businessId),
              (businessName360.current = business.businessInfo.name),
              locationId360.current,
              locationName360.current,
              location
            )
            return (
              <ListItem
                sx={{
                  backgroundColor: "#E9ECEF",
                  paddingLeft: 1,
                }}
                key={storeItem.locationId}
                disablePadding
              >
                <ListItemTextStyled
                  primary={
                    <Box display="flex" alignItems="center">
                      <span style={{ marginTop: "-3px" }}>
                        <StatusCircleIcon status={storeItem.locationStatus} size={14} />
                      </span>
                      <SelectableTypography
                        selected={
                          selectedStore !== null &&
                          selectedStore?.locationId === storeItem.locationId
                        }
                      >
                        {storeItem.locationId}
                      </SelectableTypography>
                    </Box>
                  }
                />
                <ListItemTextStyled
                  primary={
                    <SelectableTypography
                      selected={
                        selectedStore !== null && selectedStore?.locationId === storeItem.locationId
                      }
                    >
                      {storeItem.softwareName}
                    </SelectableTypography>
                  }
                />
                <ListItemTextStyled
                  primary={
                    <SelectableTypography
                      selected={
                        selectedStore !== null && selectedStore?.locationId === storeItem.locationId
                      }
                    >
                      <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
                        <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
                          <Tooltip placement="left" title={"Terminals"}>
                            <img
                              src={storeItem.terminalAlert ? terminalIconRed : terminalIcon}
                              alt="terminal icon"
                              style={{
                                marginRight: "10px",
                                backgroundColor: storeItem.terminalAlert ? "#e5b8bb" : "inherit",
                              }}
                            />
                          </Tooltip>
                          {storeItem.terminalCount}
                        </div>
                      </div>
                    </SelectableTypography>
                  }
                />

                <IconButton
                  onClick={(event) => {
                    handleStoreClick(event, storeItem)
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <ThreeSixtyMenu
                  id={`store-menu-${storeItem.locationId}`}
                  anchorEl={anchorEls[storeItem.locationId]}
                  onClose={() => {
                    handleClose(storeItem.locationId)
                  }}
                  options={storeOptions}
                />
              </ListItem>
            )
          })}
        </ListStyled>
      </CardContentStyled>
    </CardStyled>
  )
}
export const BusinessCard = memo(OverviewItemComponent)
