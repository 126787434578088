export const getPaymentMethodColor = (method: string) => {
  switch (method?.toLowerCase()) {
    case "invoice":
      return { backgroundColor: "#932fed52", color: "#240c6e" }
    case "credit":
    case "credit card":
      return { backgroundColor: "#86ccb27d", color: "#144B23" }
    case "tech support":
      return { backgroundColor: "#ba000d", color: "#c9391e" }
    default:
      return { backgroundColor: "transparent", color: "inherit" }
  }
}
