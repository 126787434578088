import React, { useRef } from "react"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import DeleteIcon from "@mui/icons-material/Delete"
import { formatAmount, formatAmountNoCents } from "../../../utils/formatAmount"
import { type CartItem } from "../../../types/cartItemTypes"
import { useGetFillPricingQuery } from "../../../redux/business/business.api"
import { Typography, InputBase } from "@mui/material"

interface MachineListProps {
  cartItems: CartItem[]
  handleDeleteFill: (index: number) => Promise<void>
  fillActionsLoading: boolean
  handleDropdownChange: (index: number, selectedAmount: string, newPrice: number) => Promise<void>
  isSubmitting: boolean
  onUpdateError?: (messages: string[]) => void
}

interface LastSelectedValue {
  selectedAmount: string
  newPrice: number
}

export const MachineList: React.FC<MachineListProps> = ({
  cartItems,
  handleDeleteFill,
  fillActionsLoading,
  handleDropdownChange,
  isSubmitting,
  onUpdateError,
}: MachineListProps) => {
  const {
    data: fillPricingData,
    isLoading: fillPricingLoading,
    error: fillPricingError,
  } = useGetFillPricingQuery({})

  const lastValuesRef = useRef<Record<string, LastSelectedValue>>({})

  if (fillPricingError !== null && fillPricingError !== undefined && !fillPricingLoading) {
    console.log(fillPricingError)
  }

  return (
    <div className="max-h-96 overflow-y-auto">
      <div className="grid grid-cols-[26%_35%_25%_auto] bg-gray-300">
        <div className="font-bold text-base p-2">Machine</div>
        <div className="font-bold text-base p-2 text-right pr-10">Fill</div>
        <div className="font-bold text-base p-2 text-right">Price</div>
        <div></div>
      </div>

      {cartItems.map((cartItem, index) => (
        <div
          key={index}
          className="grid grid-cols-[26%_35%_25%_auto] items-center border-b border-gray-300"
        >
          <div className="p-2">{cartItem.machineId}</div>
          <div className="p-2 text-right">
            <Select
              variant="outlined"
              input={<InputBase />}
              disabled={fillPricingLoading || fillActionsLoading || isSubmitting}
              value={cartItem.amount ?? ""}
              displayEmpty
              onChange={(e) => {
                const selectedAmount = e.target.value
                const newPrice = fillPricingData?.data?.fillPricing[selectedAmount]
                // Update last selected values (optional)
                const selectedAmountStr = selectedAmount.toString()
                lastValuesRef.current[cartItem.machineId] = {
                  selectedAmount: selectedAmountStr,
                  newPrice,
                }

                // Call update API
                handleDropdownChange(index, selectedAmount.toLocaleString(), newPrice).catch(
                  (err: Error) => {
                    console.error("Error updating price:", err)
                    // If a parent update error handler is provided, call it with error messages
                    if (onUpdateError != null) {
                      onUpdateError(err.message.split("; "))
                    }
                  }
                )
              }}
              MenuProps={{
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
                transformOrigin: { vertical: "top", horizontal: "right" },
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    transform: "translateX(-12.5px)",
                  },
                },
              }}
              renderValue={(selectedKey: string | number) => {
                if (
                  selectedKey === 0 ||
                  selectedKey === "" ||
                  selectedKey === "0" ||
                  selectedKey === "$0"
                ) {
                  return <Typography>Choose Fill</Typography>
                }
                return formatAmountNoCents(String(selectedKey))
              }}
            >
              {Object.entries(fillPricingData?.data?.fillPricing ?? {}).map(([amount]) => (
                <MenuItem
                  key={amount}
                  value={amount}
                  sx={{
                    justifyContent: "right",
                  }}
                >
                  {amount}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="p-2 text-right">
            <Typography>
              {cartItem.price === undefined || cartItem.price === null
                ? formatAmount(0).full
                : formatAmount(cartItem.price).full}
            </Typography>
          </div>
          <div className="p-2 text-right">
            <IconButton
              disabled={fillPricingLoading || fillActionsLoading || isSubmitting}
              onClick={() => {
                handleDeleteFill(index).catch((err) => {
                  console.error("Error submitting code:", err)
                })
              }}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ))}
    </div>
  )
}
