import React from "react"
import { Autocomplete, TextField } from "@mui/material"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import type { Machine } from "../../../types/ILicenseSupport"

interface MachineSelectorProps {
  machines: Machine[]
  selectedMachine: Machine | null
  onChange: (machine: Machine) => void
  disabled: boolean
  isMobile: boolean
}

const MachineSelector: React.FC<MachineSelectorProps> = ({
  machines,
  selectedMachine,
  onChange,
  disabled,
  isMobile,
}) => {
  return (
    <Autocomplete
      // this key assignment remounts Autocomplete effectively clearing Machine after submission
      key={selectedMachine != null ? selectedMachine.machineId : "empty"}
      options={machines}
      value={selectedMachine ?? undefined}
      onChange={(_event, newValue) => {
        if (newValue != null) onChange(newValue)
      }}
      getOptionLabel={(option) => option.machineId}
      isOptionEqualToValue={(option, value) => option.machineId === value.machineId}
      disabled={disabled}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Machine"
          required
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
            sx: {
              "& .MuiInputLabel-asterisk": { color: "red" },
            },
          }}
          placeholder={isMobile ? "Machine" : "Choose Machine"}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.machineId, inputValue)
        const parts = parse(option.machineId, matches)
        return (
          <li {...props} key={option.machineId}>
            {parts.map((part, index) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
          </li>
        )
      }}
    />
  )
}

export default MachineSelector
