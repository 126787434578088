import React from "react"
import { Skeleton } from "@mui/material"

interface LicenseSupportSkeletonProps {
  numCards: number
}

const LicenseSupportSkeleton: React.FC<LicenseSupportSkeletonProps> = ({ numCards }) => {
  return (
    <div className="w-full sm:max-w-4xl mx-auto mb-20">
      {Array.from({ length: numCards }).map((_, index) => (
        <Skeleton key={index} variant="rectangular" height={100} style={{ marginBottom: 10 }} />
      ))}
    </div>
  )
}

export default LicenseSupportSkeleton
