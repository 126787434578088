import { useCallback, useEffect, useState } from "react"
import useFillActions from "../../../hooks/useFillActions"
import {
  useGetPaymentMethodsQuery,
  useGetUserCartItemsQuery,
} from "../../../redux/business/business.api"
import { LicensingHeader } from "../../../components/LicensingHeader"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Alert, Typography } from "@mui/material"
import type { CartItem, CartItemsData } from "../../../types/cartItemTypes"
import { MachineList } from "../../../components/Licensing/OrderSummary/MachinelList"
import { Checkout } from "../../../components/Licensing/OrderSummary/Checkout"
import { OrderSummarySkeleton } from "../../../layouts/Skeletons/OrderSummarySkeleton"
import { CustomDialog } from "../../../components/CustomDialog"
import { useNavigate } from "react-router-dom"
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits"
export function OrderSummary() {
  const {
    data: cartItemData,
    error: cartItemError,
    isLoading: isCartItemLoading,
    refetch: refetchCartItemData,
  } = useGetUserCartItemsQuery({}, { refetchOnMountOrArgChange: true })

  const {
    data: contactDetailsData,
    error: contactDetailsError,
    isLoading: isContactDetailsLoading,
  } = useGetPaymentMethodsQuery({})

  const transformCartItemsToMachines = (cartItems: CartItemsData) => {
    return cartItems?.data?.cartItems?.map((item: CartItem) => ({
      cartItemId: item.cartItemId,
      machineId: item.machineId,
      amount: item.amount,
      price: item.price,
      dateTime: item.dateTime,
    }))
  }

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const { cartItem, addCartItem, fillActionsLoading, setFillActionsLoading } = useFillActions([])
  const navigate = useNavigate()
  const [allMachinesHavePrices, setAllMachinesHavePrices] = useState(true)
  const [isMachineListEmpty, setIsMachineListEmpty] = useState(false)

  useEffect(() => {
    if (cartItemData !== null && cartItemData !== undefined) {
      const saveCartItemsToArray = transformCartItemsToMachines(cartItemData)
      if (saveCartItemsToArray !== null && saveCartItemsToArray !== undefined) {
        addCartItem(saveCartItemsToArray, true)
      }
    }
  }, [cartItemData])

  useEffect(() => {
    const allHavePricesGreaterThanZero = cartItem.every((m) => {
      // Here i'm  making sure  that price is not undefined
      if (m.price === undefined || m.price === null) {
        return false
      }

      const price = m.price
      return !isNaN(price) && price > 0
    })
    console.log("allHavePricesGreaterThanZero:", allHavePricesGreaterThanZero)
    setAllMachinesHavePrices(allHavePricesGreaterThanZero)
  }, [cartItem])

  useEffect(() => {
    if (!isCartItemLoading && cartItem.length === 0) {
      setIsMachineListEmpty(true)
    } else {
      setIsMachineListEmpty(false)
    }
  }, [cartItem.length, isCartItemLoading])

  console.log("cartItemArray", cartItem)

  const getTotalPurchase = useCallback(() => {
    return parseFloat(
      cartItem
        .reduce<number>((accumulator, m) => {
          const purchaseValue = typeof m.price === "string" ? parseFloat(m.price) : m.price

          if (typeof purchaseValue === "number" && !isNaN(purchaseValue)) {
            return accumulator + purchaseValue
          } else {
            return accumulator
          }
        }, 0)
        .toFixed(0)
    )
  }, [cartItem])

  const goToPurchaseFills = () => {
    navigate("/licensing/purchase-fills")
  }

  const handleCloseDialog = () => {
    setIsMachineListEmpty(false)
  }

  if (isCartItemLoading || isContactDetailsLoading) {
    return <OrderSummarySkeleton reportName="Order Summary" />
  }

  if (
    contactDetailsError !== null &&
    contactDetailsError !== undefined &&
    cartItemError !== null &&
    cartItemError !== undefined &&
    !isCartItemLoading &&
    !isContactDetailsLoading
  ) {
    console.log(cartItemError)
  }

  return (
    <div className="w-full sm:max-w-4xl mx-auto mb-20 ">
      <nav className=" shadow-top shadow-bottom:hidden border-b border-gray-300 sticky top-0 z-50 bg-white">
        <LicensingHeader title="Order Summary" />
        {!isMachineListEmpty && (
          <Alert severity="info">
            <Typography variant="body1">
              Please review your order details below. Ensure all desired{" "}
              <span className="font-bold">machines </span> are listed before proceeding to checkout.
            </Typography>
          </Alert>
        )}
      </nav>
      {cartItem.length > 0 && <MachineList cartItems={cartItem} />}

      {cartItem.length > 0 && (
        <div
          className={`${
            isSmallScreen ? "fixed bottom-20 w-full bg-white z-50 shadow-top" : ""
          } pt-5  `}
        >
          <Checkout
            cartItems={cartItem}
            setFillActionsLoading={setFillActionsLoading}
            fillActionsLoading={fillActionsLoading}
            allMachinesHavePrices={allMachinesHavePrices}
            contactDetailsData={contactDetailsData}
            getTotalPurchase={getTotalPurchase}
            refetchCartItemData={refetchCartItemData}
          />
        </div>
      )}

      <CustomDialog
        open={isMachineListEmpty}
        title=""
        centerTitle={true}
        fullScreen={false}
        hideCloseIcon={true}
        content={
          <Alert
            icon={false}
            severity="error"
            className="w-full h-[350px] object-cover justify-center"
          >
            <div className="flex flex-col items-center w-full h-full  justify-around">
              <ProductionQuantityLimitsIcon sx={{ fontSize: "154px" }} />{" "}
              <Typography variant="body1">
                Your cart is empty. Please add at least one item to proceed with checkout.{" "}
              </Typography>
            </div>
          </Alert>
        }
        primaryActionText={"Back To Cart"}
        onPrimaryAction={goToPurchaseFills}
        handleClose={handleCloseDialog}
      />
    </div>
  )
}
