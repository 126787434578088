import { useState, useEffect, useRef, useCallback } from "react"
import { useGetOverviewMutation } from "../../redux/business/business.api"
import { ReportLayout } from "../../layouts/ReportLayout"
import { OverviewBusinesses } from "../../components/Overview/OverviewBusinesses"
import { getReportsHandler } from "../../utils/ReportHandlerInput"
import { CalendarInputType } from "../../types/reportTypes"
import { PeriodSkeleton } from "../../layouts/Skeletons/PeriodSkeleton"
import { type Dayjs } from "dayjs"
import Alert, { type AlertColor } from "@mui/material/Alert"
import { type HandleSnackBarType, LocationInputType } from "../../types/reportTypes"
import type {
  Business,
  Location,
  OverviewData,
} from "../../components/Overview/OverviewTypesFlattened"
import { showDelayedLoadingToast } from "../../utils/showDelayedLoadingToast"
import toast from "react-hot-toast"
import { flattenExport } from "../../middlewares/flattenExport"
import { overviewDetailedConfig, overviewSummaryConfig } from "../../data/exportConfigs"
import { resetFilters, setStatusFilters } from "../../redux/filters/filters.slice"
import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery, useTheme } from "@mui/material"
import { type RootState } from "../../redux/store"
import { useLocation } from "react-router-dom"

interface Props {
  openFiltersHandler?: () => void
}

export function Overview(props: Props) {
  const [fetchReport] = useGetOverviewMutation()
  const [location, setLocation] = useState<string | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showHeaderDate, setShowHeaderDate] = useState(false)
  const hasHeaderDate = false
  const requiresAPICall = true
  const [, setReportDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [headerDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")

  const [report, setReport] = useState<OverviewData | null>(null)

  const convertKeysToLowerCase = (obj: Record<string, boolean>) => {
    return Object.keys(obj).reduce<Record<string, boolean>>((acc, key) => {
      acc[key.toLowerCase()] = obj[key]
      return acc
    }, {})
  }

  const isMountedRef = useRef(true)

  const [isFetchingReport, setIsFetchingReport] = useState(true)
  const allLocationsOption = { name: "All Locations", id: null }
  const [selectedValue, setSelectedValue] = useState(allLocationsOption)
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const [showForm, setShowForm] = useState(isMobile)
  useEffect(() => {
    if (!isMobile) {
      setShowForm(true)
    }
  }, [isMobile])

  const toggleForm = useCallback(
    (show: boolean) => {
      if (!isMobile) {
        setShowForm(true)
      } else {
        setShowForm(show)
      }
    },
    [isMobile]
  )

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: number | null) => {
    setLocation(value !== null ? value.toString() : null)
  }

  const handleSetSelectedValue = (value: any | null) => {
    setSelectedValue(value)
  }
  const handleSnackBar: HandleSnackBarType = (isOpen, severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(isOpen)
  }

  useEffect(() => {
    // When the component mounts
    isMountedRef.current = true

    return () => {
      // When the component unmounts
      isMountedRef.current = false
      dispatch(resetFilters())
    }
  }, [])

  const usedLocation = useLocation()
  const query = new URLSearchParams(usedLocation.search)
  const pageFromUrl = parseInt(query.get("page") ?? "1", 10)
  const [page, setPage] = useState(pageFromUrl)
  // used to get the filters from the store
  const statusFilters = useSelector((state: RootState) => state.filters.statusFilters)
  const productFilters = useSelector((state: RootState) => state.filters.productFilters)

  const lowercaseStatusFilters = convertKeysToLowerCase(statusFilters)
  const filterLocations = (overView: OverviewData | null) => {
    if (overView == null) {
      return {
        businesses: [],
        businessCount: 0,
        locationCount: 0,
        terminalCount: 0,
      }
    }

    const noStatusFiltersSelected = Object.values(lowercaseStatusFilters).every((value) => !value)
    const noProductFiltersSelected = Object.values(productFilters).every((value) => !value)
    let businesses = overView.businesses

    if (!noStatusFiltersSelected || !noProductFiltersSelected) {
      businesses = businesses
        .map((business: Business) => ({
          ...business,
          locations: business.locations.filter((location: Location) => {
            const statusFilterValue = lowercaseStatusFilters[location.locationStatus]
            const productFilterKey = Object.keys(productFilters).find(
              (key) => key.toLowerCase() === location.productType.toLowerCase()
            )

            let productFilterValue = true
            if (productFilterKey !== undefined) {
              productFilterValue = productFilters[productFilterKey]
            }

            const statusFilterPass = noStatusFiltersSelected || statusFilterValue
            const productFilterPass = noProductFiltersSelected || productFilterValue

            return statusFilterPass && productFilterPass
          }),
        }))
        .filter((business) => business.locations.length > 0)
    }

    const businessCount = businesses.length
    const locationCount = businesses.reduce((acc, business) => acc + business.locations.length, 0)
    const terminalCount = businesses.reduce(
      (acc, business) =>
        acc + business.locations.reduce((subAcc, loc) => subAcc + loc.terminalCount, 0),
      0
    )
    return {
      businesses,
      businessCount,
      locationCount,
      terminalCount,
    }
  }

  useEffect(() => {
    ;(async () => {
      const toastId = showDelayedLoadingToast(3000, isMountedRef)
      setIsFetchingReport(true)
      try {
        const res: any = await fetchReport({ sortBy: "problematic" })
        setReport(res.data.data.overView)

        const locationStatuses = new Set<string>()
        res.data.data.overView.businesses.forEach((business: Business) => {
          business.locations.forEach((location) => {
            const status = location.locationStatus
            const capitalizedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
            locationStatuses.add(capitalizedStatus)
          })
        })

        dispatch(setStatusFilters(Array.from(locationStatuses)))
      } finally {
        clearTimeout(toastId)
        if (isMountedRef.current) {
          toast.dismiss("loadingToast") // Dismiss the loading toast
        }
        setIsFetchingReport(false)
      }
    })().catch(() => {
      setIsFetchingReport(false)
    })
  }, [])

  const { businesses, businessCount, locationCount, terminalCount } = filterLocations(
    report as unknown as OverviewData
  )

  const filteredData = filterLocations(report as unknown as OverviewData)
  const exportFlattenedDetailedData =
    filteredData != null && filteredData.businesses.length !== 0
      ? flattenExport(Object.values(filteredData?.businesses), overviewDetailedConfig)
      : []
  const exportFlattenedSummaryData =
    filteredData != null && filteredData.businesses.length !== 0
      ? flattenExport(Object.values(filteredData?.businesses), overviewSummaryConfig)
      : []
  return (
    <ReportLayout
      hasExport={true}
      exportFlattenedDetailedData={exportFlattenedDetailedData}
      exportFlattenedSummaryData={exportFlattenedSummaryData}
      reportName={"Overview"}
      calendarInput={CalendarInputType.NoDate}
      locationInput={LocationInputType.AutoLoad}
      showHeaderDate={showHeaderDate}
      headerDateRange={headerDateRange}
      showForm={showForm}
      accordionHeadings={[]}
      showAccordionHeadings={false}
      toggleForm={toggleForm}
      handleLocationChange={handleLocationChange}
      selectedValue={selectedValue}
      handleSetSelectedValue={handleSetSelectedValue}
      setReportDateRange={setReportDateRange}
      openFiltersHandler={props.openFiltersHandler}
      location={location}
      isFetchingReport={isFetchingReport}
      isActiveReport={Boolean(report != null)}
      getReportHandler={async () => {
        try {
          if (location !== null) {
            await getReportsHandler(
              fetchReport,
              setIsFetchingReport,
              handleSnackBar,
              setReport,
              location,
              hasHeaderDate,
              requiresAPICall,
              setShowHeaderDate,
              "data.data.storeList",
              {},
              isMountedRef
            )
          } else {
            handleSnackBar(true, "error", "Please select a location first.")
          }
        } catch (error) {
          console.log("error", error)
        }
      }}
    >
      <>
        {isFetchingReport && <PeriodSkeleton />}
        {!isFetchingReport && filteredData == null && snackbarOpen && (
          <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        )}
        {!isFetchingReport && filteredData !== null && filteredData != null && (
          <OverviewBusinesses
            businessCount={businessCount}
            locationCount={locationCount}
            terminalCount={terminalCount}
            businesses={businesses}
            page={page}
            setPage={setPage}
          />
        )}
      </>
    </ReportLayout>
  )
}
