import { useState, useCallback } from "react"
import type { Machine, Fill, DialogMode } from "../types/ILicenseSupport"
import {
  useIssueSupportCodeMutation,
  useReissueSupportCodeMutation,
} from "../redux/business/business.api"
import { parseDollarString } from "../utils/parseDollarString"

export function useLicenseSupport(fillPricingData: Record<string, number>) {
  const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null)
  const [selectedFill, setSelectedFill] = useState<Fill>({ amount: "", price: 0 })
  const [reason, setReason] = useState("")
  const [error, setError] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fillCode, setFillCode] = useState<string | null>(null)
  const [dialogMode, setDialogMode] = useState<DialogMode>("none")

  const [issueSupportCode] = useIssueSupportCodeMutation()
  const [reissueSupportCode] = useReissueSupportCodeMutation()

  const handleMachineChange = useCallback((machine: Machine) => {
    setSelectedMachine(machine)
    // Reset fill and reason when machine changes
    setSelectedFill({ amount: "", price: 0 })
    setReason("")
    setError("")
  }, [])

  // Updated: Look up the fill price from pricing data
  const handleFillChange = useCallback(
    (amount: string) => {
      const price = fillPricingData[amount] ?? 0
      setSelectedFill({ amount, price })
    },
    [fillPricingData]
  )

  const submitSupportCode = useCallback(
    async (isReissue: boolean) => {
      if (
        selectedMachine == null ||
        reason.trim().length === 0 ||
        selectedFill.amount.trim().length === 0
      ) {
        setError("Please fill in all required fields.")
        return
      }
      setError("")
      setIsSubmitting(true)

      try {
        const mutationFn = isReissue ? reissueSupportCode : issueSupportCode
        const response = await mutationFn({
          machineId: selectedMachine.machineId,
          amount: parseDollarString(selectedFill.amount),
          reason,
        }).unwrap()

        if (response.rc === 1000) {
          setFillCode(response.data.fillCode)
          setDialogMode("result")
          setSelectedMachine(null)
          setSelectedFill({ amount: "", price: 0 })
          setReason("")
        } else {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          setError(`${response.message} Failed to ${isReissue ? "re-issue" : "issue"} fill code.`)
        }
      } catch {
        setError("An error occurred while contacting the server.")
      } finally {
        setIsSubmitting(false)
      }
    },
    [selectedMachine, selectedFill, reason, issueSupportCode, reissueSupportCode]
  )

  const handleConfirmIssueCode = useCallback(async () => {
    setDialogMode("none")
    await submitSupportCode(false)
  }, [submitSupportCode])

  const handleConfirmReissueCode = useCallback(async () => {
    setDialogMode("none")
    await submitSupportCode(true)
  }, [submitSupportCode])

  const openIssueConfirmDialog = useCallback(() => {
    setDialogMode("issueConfirm")
  }, [])

  const openReissueConfirmDialog = useCallback(() => {
    setDialogMode("reissueConfirm")
  }, [])

  const closeDialog = useCallback(() => {
    setDialogMode("none")
  }, [])

  return {
    selectedMachine,
    selectedFill,
    reason,
    error,
    isSubmitting,
    fillCode,
    dialogMode,
    handleMachineChange,
    handleFillChange,
    setReason,
    openIssueConfirmDialog,
    openReissueConfirmDialog,
    handleConfirmIssueCode,
    handleConfirmReissueCode,
    closeDialog,
    setError,
  }
}
