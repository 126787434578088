import React from "react"
import { Autocomplete, TextField } from "@mui/material"

interface FillSelectorProps {
  fillOptions: string[]
  selectedFill: string
  onChange: (value: string) => void
  disabled: boolean
  isMobile: boolean
}

const FillSelector: React.FC<FillSelectorProps> = ({
  fillOptions,
  selectedFill,
  onChange,
  disabled,
  isMobile,
}) => {
  return (
    <Autocomplete
      options={fillOptions}
      value={selectedFill}
      onChange={(_event, value) => {
        if (value != null) onChange(value)
      }}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      disabled={disabled}
      disableClearable
      // Do not filter based on the input.
      filterOptions={(options) => options}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Fill"
          required
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
            sx: {
              "& .MuiInputLabel-asterisk": { color: "red" },
            },
          }}
          placeholder={isMobile ? "Fill" : "Choose Fill"}
          // Make the field non-editable
          inputProps={{
            ...params.inputProps,
            readOnly: true,
          }}
        />
      )}
      // Render options without highlighting.
      renderOption={(props, option) => (
        <li {...props} key={option}>
          <span>{option}</span>
        </li>
      )}
    />
  )
}

export default FillSelector
