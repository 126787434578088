import React from "react"
import { Alert } from "@mui/material"
import { PurchaseReportItem } from "./PurchaseReportItem"
import type { PurchaseReportProps } from "../../../types/IFillHistory"

export function PurchaseReport({ report }: PurchaseReportProps) {
  if (report === null || report === undefined || report.transactionsTotal === 0) {
    return (
      <Alert severity="error" sx={{ width: "100%" }}>
        No data to show with the current selection.
      </Alert>
    )
  }

  return (
    <>
      <div className="shadow-lg">
        {report.transactions.map(
          (
            { transactionId, purchaseDate, purchaseMethod, reason, purchaseTotal, machines },
            index
          ) => {
            return (
              <React.Fragment key={index}>
                <PurchaseReportItem
                  transactionId={transactionId}
                  purchaseDate={purchaseDate}
                  purchaseMethod={purchaseMethod}
                  reason={reason}
                  purchaseTotal={purchaseTotal}
                  machines={machines}
                />
              </React.Fragment>
            )
          }
        )}
      </div>
    </>
  )
}
