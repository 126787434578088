import React, { createContext, useContext } from "react"
import type { Machine } from "../types/ILicenseSupport"
import { useLicenseSupport } from "../hooks/useLicenseSupport"

export interface LicenseSupportContextType {
  // State and handlers from the hook:
  selectedMachine: ReturnType<typeof useLicenseSupport>["selectedMachine"]
  selectedFill: ReturnType<typeof useLicenseSupport>["selectedFill"]
  reason: string
  error: string
  isSubmitting: boolean
  fillCode: string | null
  dialogMode: ReturnType<typeof useLicenseSupport>["dialogMode"]
  handleMachineChange: ReturnType<typeof useLicenseSupport>["handleMachineChange"]
  handleFillChange: ReturnType<typeof useLicenseSupport>["handleFillChange"]
  setReason: ReturnType<typeof useLicenseSupport>["setReason"]
  openIssueConfirmDialog: ReturnType<typeof useLicenseSupport>["openIssueConfirmDialog"]
  openReissueConfirmDialog: ReturnType<typeof useLicenseSupport>["openReissueConfirmDialog"]
  handleConfirmIssueCode: ReturnType<typeof useLicenseSupport>["handleConfirmIssueCode"]
  handleConfirmReissueCode: ReturnType<typeof useLicenseSupport>["handleConfirmReissueCode"]
  closeDialog: ReturnType<typeof useLicenseSupport>["closeDialog"]
  setError: ReturnType<typeof useLicenseSupport>["setError"]
  // Additional data passed from the container:
  machines: Machine[]
  fillPricingData: Record<string, number>
}

const LicenseSupportContext = createContext<LicenseSupportContextType | undefined>(undefined)

interface LicenseSupportProviderProps {
  machines: Machine[]
  fillPricingData: Record<string, number>
  children: React.ReactNode
}

export const LicenseSupportProvider: React.FC<LicenseSupportProviderProps> = ({
  machines,
  fillPricingData,
  children,
}) => {
  const licenseSupport = useLicenseSupport(fillPricingData)
  const value: LicenseSupportContextType = {
    ...licenseSupport,
    machines,
    fillPricingData,
  }
  return <LicenseSupportContext.Provider value={value}>{children}</LicenseSupportContext.Provider>
}

export const useLicenseSupportContext = () => {
  const context = useContext(LicenseSupportContext)
  if (context == null) {
    throw new Error("useLicenseSupportContext must be used within a LicenseSupportProvider")
  }
  return context
}
