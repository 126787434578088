import React from "react"
import Typography from "@mui/material/Typography"

const LicenseSupportInputsHeader: React.FC = () => (
  <div className="grid grid-cols-[40%_40%_20%] bg-gray-300">
    <Typography variant="body1" sx={{ fontWeight: 700, padding: 1, paddingLeft: 2 }}>
      Machine
    </Typography>
    <Typography variant="body1" sx={{ fontWeight: 700, padding: 1, paddingLeft: 2 }}>
      Fill
    </Typography>
    <Typography
      variant="body1"
      sx={{ fontWeight: 700, padding: 1, paddingRight: 2, textAlign: "right" }}
    >
      Price
    </Typography>
  </div>
)

export default LicenseSupportInputsHeader
