import { Button, CircularProgress } from "@mui/material"
import customStyles from "../styles/tokens.json"

interface PrimaryButtonProps {
  primaryActionText: string
  disabledPrimaryAction?: boolean
  isLoading?: boolean
  onPrimaryAction?: () => void
}

export const CustomDialogPrimaryButton = ({
  primaryActionText,
  onPrimaryAction,
  disabledPrimaryAction,
  isLoading = false,
}: PrimaryButtonProps) => {
  return (
    <Button
      sx={{
        fontSize: "min(16px, calc(10px + 0.8vmin))",
        width: "100%",
        bgcolor: customStyles.components.buttons.primary.color,
        ":active": { bgcolor: customStyles.components.buttons.primary.hoverColor },
      }}
      variant="contained"
      onClick={(event) => {
        event.stopPropagation() // Prevent event bubbling that might affect state
        event.preventDefault()
        if (onPrimaryAction != null) {
          onPrimaryAction() // Execute the provided action
        }
      }}
      onTouchEnd={(event) => {
        event.preventDefault() // Prevent default touch behavior
        event.stopPropagation() // Stop touch event propagation
        if (onPrimaryAction != null) {
          onPrimaryAction()
        }
      }}
      disabled={disabledPrimaryAction}
    >
      {isLoading !== null && isLoading !== undefined && isLoading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
      {primaryActionText}
    </Button>
  )
}
