import React, { useEffect, useState } from "react"
import { formatAmount } from "../../../utils/formatAmount"
import {
  Alert,
  Backdrop,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
  Snackbar,
} from "@mui/material"
import ShoppingCartCheckoutOutlinedIcon from "@mui/icons-material/ShoppingCartCheckoutOutlined"
import {
  useCreateCheckoutMutation,
  useCreateInvoiceMutation,
} from "../../../redux/business/business.api"
import type { ContactDetailsResponse } from "../../../types/contactDetailsTypes"
import { ReactComponent as AnimatedShoppingCard } from "../../../assets/licensing/animated_shopping_cart.svg"
import type { CartItem } from "../../../types/cartItemTypes"
import { CustomDialog } from "../../CustomDialog"
import { useNavigate } from "react-router-dom"
import { formatLicensingErrorMessages } from "../../../utils/formatLicensingErrorMessages"
import NotificationAlert from "../../Common/NotificationAlert"

interface CheckoutProps {
  cartItems: CartItem[]
  getTotalPurchase: () => number
  setFillActionsLoading: React.Dispatch<React.SetStateAction<boolean>>
  fillActionsLoading: boolean
  allMachinesHavePrices: boolean
  contactDetailsData: ContactDetailsResponse
  refetchCartItemData: any
}

export const Checkout: React.FC<CheckoutProps> = ({
  cartItems,
  getTotalPurchase,
  setFillActionsLoading,
  fillActionsLoading,
  allMachinesHavePrices,
  contactDetailsData,
  refetchCartItemData,
}: CheckoutProps) => {
  const navigate = useNavigate()
  const [createCheckout] = useCreateCheckoutMutation()
  const [createInvoice] = useCreateInvoiceMutation()
  const [spinnerCheckout, setSpinnerCheckout] = useState(false)

  // Payment method selection
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null)

  // State to capture sequence errors from checkout (rc:2015 or rc:2021)
  const [checkoutSequenceError, setCheckoutSequenceError] = useState(false)
  const [checkoutErrorMessages, setCheckoutErrorMessages] = useState<string[]>([])
  const [openCartChangedAlert, setOpenCartChangedAlert] = useState(false)

  // State for invoice confirmation dialog
  const [showInvoiceConfirmation, setShowInvoiceConfirmation] = useState(false)

  const paymentMethods = contactDetailsData?.data?.paymentMethods
  const fillInvoice = paymentMethods?.fillInvoice
  const fillCC = paymentMethods?.fillCC
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

  // Set default payment method based on available options
  useEffect(() => {
    if (contactDetailsData != null) {
      if (fillInvoice && !fillCC) {
        setSelectedPaymentMethod("Invoice")
      } else if (!fillInvoice && fillCC) {
        setSelectedPaymentMethod("Credit Card")
      }
    }
  }, [fillInvoice, fillCC, contactDetailsData])

  // If checkout sequence error occurs, navigate back to purchase fills
  const navigateToPurchaseFills = () => {
    navigate("/licensing/purchase-fills")
  }

  // Function to perform checkout.
  const handleCheckout = async () => {
    setFillActionsLoading(true)
    setSpinnerCheckout(true)
    try {
      // Re-fetch cart items to ensure data is current.
      const { data: refetchedData } = await refetchCartItemData().unwrap()
      const refetchedCartItems = refetchedData.cartItems.map((item: CartItem) => ({
        cartItemId: item.cartItemId,
        amount: item.amount,
        machineId: item.machineId,
        price: item.price,
      }))

      // Check if any changes exist between local and refetched cart items.
      const hasChanges =
        cartItems.length !== refetchedCartItems.length ||
        cartItems.some((item) => {
          const foundItem: CartItem | undefined = refetchedCartItems.find(
            (refItem: CartItem) => refItem.cartItemId === item.cartItemId
          )
          return (
            foundItem?.amount !== item?.amount ||
            foundItem?.price !== item?.price ||
            foundItem?.machineId !== item?.machineId
          )
        })

      if (hasChanges) {
        setOpenCartChangedAlert(true)
      } else {
        const checkoutMethod = selectedPaymentMethod === "Invoice" ? createInvoice : createCheckout
        const response = await checkoutMethod({
          returnRedirectUrl: `${window.location.protocol}//${window.location.host}/licensing/payment-status`,
          purchaseMethod: selectedPaymentMethod,
        }).unwrap()

        if (response?.rc === 1000) {
          // Successful checkout – proceed as normal.
          if (selectedPaymentMethod === "Invoice") {
            if (response?.data?.invoiceId != null) {
              localStorage.setItem("invoiceId", response.data.invoiceId)
              localStorage.removeItem("orderId")
              navigate("/licensing/payment-status")
            }
          } else if (response?.data?.checkout?.url != null) {
            if (response?.data?.checkout?.orderId != null) {
              localStorage.setItem("orderId", response.data.checkout.orderId)
              localStorage.removeItem("invoiceId")
            }
            window.location.href = response.data.checkout.url
          } else {
            console.error("Unexpected response format:", response)
          }
        } else if (response?.rc === 2015) {
          // Sequence error: update local state to show error alert.
          const messages = formatLicensingErrorMessages(response)
          setCheckoutSequenceError(true)
          setCheckoutErrorMessages(messages)
          setOpenCartChangedAlert(false)
        } else if (response?.rc === 2021) {
          const messages = formatLicensingErrorMessages(response)
          setCheckoutSequenceError(true)
          setCheckoutErrorMessages(messages)
        } else {
          console.error("Checkout response error:", response)
        }
      }
    } catch (err: any) {
      let messages: string[] = []
      if (err?.data != null) {
        messages = formatLicensingErrorMessages(err.data)
      } else if (err instanceof Error) {
        messages = [err.message]
      } else {
        messages = ["An unexpected error occurred during checkout."]
      }
      setCheckoutSequenceError(true)
      setCheckoutErrorMessages(messages)
      console.error("Checkout error:", messages)
    } finally {
      setSpinnerCheckout(false)
      setFillActionsLoading(false)
    }
  }

  // When paying by invoice, show confirmation dialog before checkout.
  const handleCheckoutConfirmation = async () => {
    setShowInvoiceConfirmation(false)
    await handleCheckout()
  }

  const handleCloseAlert = () => {
    setOpenCartChangedAlert(false)
  }

  return (
    <>
      <div
        className={`flex ${
          fillCC && fillInvoice ? "justify-between" : "justify-end"
        } items-center px-2`}
      >
        {spinnerCheckout && (
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: 2147483647,
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            open={true}
          >
            <AnimatedShoppingCard />
            <Typography variant="h5">Checking out...</Typography>
          </Backdrop>
        )}

        {fillCC && fillInvoice && (
          <RadioGroup
            name="use-radio-group"
            value={selectedPaymentMethod ?? ""}
            onChange={(event) => {
              setSelectedPaymentMethod(event.target.value)
            }}
            row={!isSmallScreen}
          >
            <FormControlLabel
              value="Credit Card"
              label="Credit Card"
              disabled={!fillCC || spinnerCheckout}
              control={<Radio />}
            />
            <FormControlLabel
              value="Invoice"
              label="Invoice"
              disabled={!fillInvoice || spinnerCheckout}
              control={<Radio />}
            />
          </RadioGroup>
        )}

        {getTotalPurchase() > 50000 && allMachinesHavePrices ? (
          <Alert severity="warning" sx={{ width: "100%" }}>
            <Typography variant="body1">
              Your purchase cannot be over $50,000 in a single transaction. Please adjust your cart.
            </Typography>
          </Alert>
        ) : (
          <Button
            startIcon={<ShoppingCartCheckoutOutlinedIcon />}
            disabled={
              fillActionsLoading ||
              getTotalPurchase() === 0 ||
              !allMachinesHavePrices ||
              selectedPaymentMethod === null ||
              spinnerCheckout
            }
            variant="contained"
            sx={{
              backgroundColor: "#FCA311",
              color: "white",
              height: "36.5px",
              "&:hover": {
                backgroundColor: "rgba(252, 163, 17, 0.8)",
              },
              "&.Mui-disabled": {
                backgroundColor: "rgba(252, 163, 17, 0.3)",
              },
            }}
            onClick={() => {
              if (selectedPaymentMethod === "Invoice") {
                setShowInvoiceConfirmation(true)
              } else {
                handleCheckout().catch(() => {
                  // Errors are handled via state.
                })
              }
            }}
          >
            Checkout
            {allMachinesHavePrices && (
              <span className="font-bold pl-1">{formatAmount(getTotalPurchase()).full}</span>
            )}
          </Button>
        )}
      </div>

      {checkoutSequenceError && (
        <Snackbar open={true} sx={{ position: "static" }}>
          <Alert
            severity="error"
            sx={{ bottom: 0, mt: 2, width: "fill-available" }}
            action={
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                onClick={navigateToPurchaseFills}
              >
                {isSmallScreen ? "Back" : "Back To Cart"}
              </Button>
            }
          >
            <Typography variant="body1" fontWeight={700}>
              Unable to complete request:
            </Typography>
            <ul>
              {checkoutErrorMessages.map((msg, index) => (
                <li key={index}>
                  <Typography variant="body2">{msg}</Typography>
                </li>
              ))}
            </ul>
          </Alert>
        </Snackbar>
      )}

      <NotificationAlert
        open={openCartChangedAlert}
        severity="error"
        message="Your items have been updated. Please review your order before proceeding to checkout."
        onClose={handleCloseAlert}
        autoHideDuration={360000}
      />

      <CustomDialog
        open={showInvoiceConfirmation}
        title=""
        centerTitle={true}
        fullScreen={false}
        hideCloseIcon={true}
        dialogContentText="You selected to pay by invoice. Are you sure you want to continue?"
        primaryActionText="Checkout"
        secondaryActionText="Cancel"
        onPrimaryAction={() => {
          handleCheckoutConfirmation().catch((error) => {
            console.error("Error during checkout confirmation:", error)
          })
        }}
        onSecondaryAction={() => {
          setShowInvoiceConfirmation(false)
        }}
        handleClose={() => {
          setShowInvoiceConfirmation(false)
        }}
      />
    </>
  )
}
