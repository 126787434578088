import { useCallback, useEffect, useRef, useState } from "react"
import jsQR from "jsqr"

interface CornerPoints {
  topLeftCorner: { x: number; y: number }
  topRightCorner: { x: number; y: number }
  bottomRightCorner: { x: number; y: number }
  bottomLeftCorner: { x: number; y: number }
}

export interface QRNotification {
  open: boolean
  severity: "error" | "warning" | "info" | "success"
  message: string
  cameraAccess?: boolean
}

export const useQRScanner = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [data, setData] = useState("")
  const [isScanning, setIsScanning] = useState(false)
  const isScanningRef = useRef(isScanning)
  const [openScanDialog, setOpenScanDialog] = useState(false)
  const [isCameraDenied, setIsCameraDenied] = useState(false)
  const [notification, setNotification] = useState<QRNotification | null>(null)
  const isVideoActiveRef = useRef(false)
  const videoStreamRef = useRef<MediaStream | null>(null)
  const MAX_FILE_SIZE = 20 * 1024 * 1024

  // Helper functions for notifications
  const showNotification = (
    severity: "error" | "warning" | "info" | "success",
    message: string,
    cameraAccess?: boolean
  ) => {
    setNotification({ open: true, severity, message, cameraAccess })
  }

  const clearNotification = () => {
    setNotification(null)
  }

  const resetScanning = useCallback(() => {
    setIsScanning(false)
    setData("")
  }, [])

  const handleScanClick = () => {
    setOpenScanDialog(true)
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target
    if (fileInput.files?.[0] != null) {
      const file = fileInput.files[0]
      scanFromImage(file)
      // Reset the file input
      fileInput.value = ""
    }
  }

  const handleCloseDialog = () => {
    setOpenScanDialog(false)
    stopVideo()
    resetScanning()
  }

  const validateQRCodeData = (scannedData: string) => {
    const urlPattern = /^https?:\/\/.*\?c=.+/
    if (!urlPattern.test(scannedData)) {
      clearNotification()
      showNotification("error", "Not a valid QR code.")
      handleCloseDialog()
      return false
    } else {
      const url = new URL(scannedData)
      const cParam = url.searchParams.get("c")
      if (cParam === null || cParam === "undefined") {
        clearNotification()
        showNotification("error", "Not a valid QR code.")
        handleCloseDialog()
        return false
      }
    }
    return true
  }

  const startVideo = useCallback(() => {
    setIsCameraDenied(false)
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((stream) => {
        videoStreamRef.current = stream
        if (videoRef.current != null) {
          videoRef.current.srcObject = stream
          videoRef.current.play().catch((error) => {
            console.error("Video play failed", error)
          })
          isVideoActiveRef.current = true
        }
      })
      .catch((error) => {
        console.error("Error accessing the camera", error)
        if (error.name === "NotAllowedError") {
          clearNotification()
          showNotification(
            "error",
            "Camera access denied. Please enable camera access in your browser settings to continue.",
            true
          )
          setIsCameraDenied(true)
        }
      })
  }, [])

  const stopVideo = useCallback(() => {
    if (videoStreamRef.current !== null && videoStreamRef.current !== undefined) {
      videoStreamRef.current.getTracks().forEach((track) => {
        track.stop()
      })
      if (videoRef.current != null) {
        videoRef.current.srcObject = null
      }
      videoStreamRef.current = null
      isVideoActiveRef.current = false
    }
  }, [])

  const drawGreenLine = (canvas: HTMLCanvasElement, corners: CornerPoints) => {
    const context = canvas.getContext("2d")
    if (context == null) return
    context.beginPath()
    context.strokeStyle = "green"
    context.lineWidth = 4
    context.moveTo(corners.topLeftCorner.x, corners.topLeftCorner.y)
    context.lineTo(corners.topRightCorner.x, corners.topRightCorner.y)
    context.lineTo(corners.bottomRightCorner.x, corners.bottomRightCorner.y)
    context.lineTo(corners.bottomLeftCorner.x, corners.bottomLeftCorner.y)
    context.lineTo(corners.topLeftCorner.x, corners.topLeftCorner.y)
    context.stroke()
  }

  const scan = useCallback(() => {
    setData("")
    if (videoRef.current != null) {
      const video = videoRef.current
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        const canvas = document.createElement("canvas")
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        const context = canvas.getContext("2d")
        if (context != null) {
          context.drawImage(video, 0, 0, canvas.width, canvas.height)
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
          const qrCode = jsQR(imageData.data, imageData.width, imageData.height)
          if (qrCode != null && validateQRCodeData(qrCode.data)) {
            setData(qrCode.data)
            setIsScanning(false)
            stopVideo()
            drawGreenLine(canvas, qrCode.location)
          } else {
            requestAnimationFrame(scan)
          }
        } else {
          console.log("Canvas context not obtained.")
        }
      } else {
        requestAnimationFrame(scan)
      }
    }
  }, [stopVideo])

  useEffect(() => {
    isScanningRef.current = isScanning
    if (isScanning) {
      scan()
    }
  }, [isScanning, scan])

  const initiateScan = useCallback(() => {
    if (!isVideoActiveRef.current) {
      startVideo()
    }
    setIsScanning(true)
    if (videoRef.current != null && videoRef.current.readyState >= 2) {
      scan()
    }
  }, [scan, startVideo])

  const stopScan = useCallback(() => {
    setIsScanning(false)
  }, [])

  const scanFromImage = useCallback((file: File) => {
    setData("")

    if (file.size > MAX_FILE_SIZE) {
      clearNotification()
      showNotification("error", "This file is too large. Please keep it under 20 MB.")
      return
    }

    const objectURL = URL.createObjectURL(file)

    createImageBitmap(file)
      .then((bitmap) => {
        // Downscale if needed
        const MAX_DIMENSION = 1200
        let { width, height } = bitmap
        if (width > MAX_DIMENSION || height > MAX_DIMENSION) {
          const scale = Math.min(MAX_DIMENSION / width, MAX_DIMENSION / height)
          width = Math.floor(width * scale)
          height = Math.floor(height * scale)
        }

        const canvas = document.createElement("canvas")
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext("2d")
        if (ctx == null) {
          clearNotification()
          showNotification("error", "Unable to get canvas context.")
          URL.revokeObjectURL(objectURL)
          return
        }

        // Draw the image as-is onto the canvas
        ctx.drawImage(bitmap, 0, 0, width, height)

        // Get the raw image data
        const imageData = ctx.getImageData(0, 0, width, height)
        const data = imageData.data
        const len = data.length

        // First pass: compute average brightness for adaptive threshold
        let sumBrightness = 0
        for (let i = 0; i < len; i += 4) {
          // Standard luminance formula
          const brightness = 0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2]
          sumBrightness += brightness
        }
        const avgBrightness = sumBrightness / (len / 4)

        // Second pass: apply thresholding to binarize the image
        // You could also experiment with offsetting the threshold (e.g. avgBrightness * 0.9) if needed.
        for (let i = 0; i < len; i += 4) {
          const brightness = 0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2]
          const value = brightness > avgBrightness ? 255 : 0
          data[i] = data[i + 1] = data[i + 2] = value
          // Leave alpha unchanged
        }
        // Write the processed data back (optional, for debugging you might want to see it)
        ctx.putImageData(imageData, 0, 0)

        // Now attempt to decode the QR code using the preprocessed data
        const qrCode = jsQR(data, width, height)
        if (qrCode !== null) {
          const isValid = validateQRCodeData(qrCode.data)
          if (isValid) {
            clearNotification()
            setData(qrCode.data)
          }
        } else {
          clearNotification()
          showNotification("error", "No QR code found.")
        }
        URL.revokeObjectURL(objectURL)
      })
      .catch((error) => {
        console.error("Failed to process image.", error)
        clearNotification()
        showNotification("error", "Failed to process image.")
        URL.revokeObjectURL(objectURL)
      })
  }, [])

  useEffect(() => {
    return () => {
      stopVideo()
    }
  }, [stopVideo])

  return {
    videoRef,
    data,
    scanFromImage,
    initiateScan,
    stopScan,
    stopVideo,
    openScanDialog,
    setOpenScanDialog,
    handleScanClick,
    handleCloseDialog,
    handleFileUpload,
    isCameraDenied,
    // Expose the notification state and clear function for rendering the alert
    notification,
    clearNotification,
  }
}
