export interface FillSequenceError {
  machine: string
  requestFillCount: number
  machineFillCount: number
}

export interface MachineCartConflictError {
  machine: string
  user: string
  minutes: number
}

/**
 * Given an API error response, return an array of formatted error messages.
 * - rc 2015 indicates Sequence Errors.
 * - rc 2021 indicates Machine Conflict Errors.
 * - rc 2013 indicates items already exists in the cart.
 */
export function formatLicensingErrorMessages(errorResponse: any): string[] {
  const messages: string[] = []

  if (
    errorResponse != null &&
    errorResponse.rc === 2015 &&
    Array.isArray(errorResponse.SequenceErrors)
  ) {
    errorResponse.SequenceErrors.forEach((err: FillSequenceError) => {
      if (err?.requestFillCount != null && err.machineFillCount != null) {
        messages.push(
          `- [${err.machine}] This Request Code [${err.requestFillCount}] has been processed and a newer Fill Code [${err.machineFillCount}] was already issued.`
        )
      }
    })
  }

  if (
    errorResponse != null &&
    errorResponse.rc === 2021 &&
    Array.isArray(errorResponse.MachineCartConflictErrors)
  ) {
    errorResponse.MachineCartConflictErrors.forEach((err: MachineCartConflictError) => {
      if (err?.machine != null && err?.user != null) {
        messages.push(
          `Machine ID ${err.machine} is already in ${err.user}'s cart. Please wait for their purchase to conclude or contact them to remove the item from their cart.`
        )
      }
    })
  }

  if (errorResponse != null && errorResponse.rc === 2013) {
    messages.push(errorResponse.message)
  }

  if (errorResponse != null && errorResponse.rc === 2011) {
    messages.push(errorResponse.message)
  }

  return messages
}
