import React from "react"
import { Typography, useMediaQuery, useTheme } from "@mui/material"
import MachineSelector from "./MachineSelector"
import FillSelector from "./FillSelector"
import ReasonInput from "./ReasonInput"
import { useLicenseSupportContext } from "../../../context/LicenseSupportContext"
import LicenseSupportInputsHeader from "./LicenseSupportInputsHeader"

const LicenseSupportInputs: React.FC = () => {
  const {
    machines,
    selectedMachine,
    handleMachineChange,
    fillPricingData,
    selectedFill,
    handleFillChange,
    reason,
    setReason,
    isSubmitting,
  } = useLicenseSupportContext()

  const fillOptions = Object.keys(fillPricingData)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))

  return (
    <div className="mt-4 max-h-96 sm:rounded border border-gray-200">
      <LicenseSupportInputsHeader />

      <div className="grid grid-cols-[40%_40%_20%] items-center">
        <div className="mt-2 p-2">
          <MachineSelector
            machines={machines}
            selectedMachine={selectedMachine}
            onChange={handleMachineChange}
            disabled={isSubmitting}
            isMobile={isMobile}
          />
        </div>
        <div className="mt-2 p-2">
          <FillSelector
            fillOptions={fillOptions}
            selectedFill={selectedFill.amount}
            onChange={handleFillChange}
            disabled={isSubmitting || selectedMachine == null}
            isMobile={isMobile}
          />
        </div>
        <div className="mt-2 p-2">
          <Typography variant="body1" className="text-right p-2 truncate">
            ${selectedFill.price.toFixed(2)}
          </Typography>
        </div>
      </div>

      <div className="mt-4 p-2">
        <ReasonInput
          reason={reason}
          onChange={setReason}
          disabled={isSubmitting || selectedMachine == null}
        />
      </div>
    </div>
  )
}

export default LicenseSupportInputs
