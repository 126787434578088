import React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  ClickAwayListener,
} from "@mui/material"
import { useLicenseSupportContext } from "../../../context/LicenseSupportContext"
import { formatFillCode } from "../../../utils/formatFillCode"

const LicenseSupportDialog: React.FC = () => {
  const {
    dialogMode,
    selectedMachine,
    selectedFill,
    reason,
    fillCode,
    handleConfirmIssueCode,
    handleConfirmReissueCode,
    closeDialog,
  } = useLicenseSupportContext()

  if (dialogMode === "none") return null

  let title = ""
  let onConfirm: () => void = closeDialog

  if (dialogMode === "issueConfirm") {
    title = "This action will issue a new Fill Code and increment the fill count."
    onConfirm = () => {
      void handleConfirmIssueCode()
    }
  } else if (dialogMode === "reissueConfirm") {
    title = "This action will re-issue a new Fill Code for the current fill count."
    onConfirm = () => {
      void handleConfirmReissueCode()
    }
  } else if (dialogMode === "result") {
    title = "Fill Code Generated"
  }

  return (
    <ClickAwayListener onClickAway={dialogMode == null ? closeDialog : () => {}}>
      <Dialog
        open
        onClose={dialogMode == null ? closeDialog : () => {}}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <DialogContent>
          {dialogMode === "result" ? (
            <DialogContentText id="dialog-description">
              <strong>{fillCode != null ? formatFillCode(fillCode) : "N/A"}</strong>
            </DialogContentText>
          ) : (
            <>
              <Typography variant="body1" gutterBottom>
                <strong>Machine Owner:</strong> {selectedMachine?.ownerName ?? "N/A"}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Machine ID:</strong> {selectedMachine?.machineId ?? "N/A"}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Amount:</strong> {selectedFill.amount}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Reason:</strong> {reason}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Are you sure you want to proceed?
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {dialogMode === "result" ? (
            <>
              <Button
                onClick={() => {
                  if (fillCode != null) {
                    navigator.clipboard.writeText(formatFillCode(fillCode)).catch(console.error)
                  }
                  closeDialog()
                }}
                variant="outlined"
                color="primary"
              >
                Copy
              </Button>
              <Button onClick={closeDialog} variant="outlined" color="primary" autoFocus>
                Close
              </Button>
            </>
          ) : (
            <>
              <Button onClick={closeDialog} variant="contained" color="primary">
                No
              </Button>
              <Button onClick={onConfirm} variant="contained" color="error" autoFocus>
                Yes
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </ClickAwayListener>
  )
}

export default LicenseSupportDialog
