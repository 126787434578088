import { RoutesContainer } from "./routes/RoutesContainer"
import toast, { Toaster } from "react-hot-toast"
import { ThemeProvider } from "@mui/material/styles"
import { useVerifyTokenQuery } from "./redux/user/user.api"
import { useEffect, useState } from "react"
import { CircularProgress } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import OfflineBackdrop from "./components/OfflineBackDrop"
import { setNavigateFunction } from "./services/NavigationService"
import { isPasswordResetPage } from "./utils/isPasswordResetPage"
import useAuth from "./hooks/useAuth"
import { customTheme } from "./theme"
import { initAnalytics, trackPageView, trackEvent } from "./services/analyticsService"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { ActivityMonitoring } from "./components/ActivityMonitoring"
import "./App.css"
import { useGetNewArticlesQuery } from "./redux/business/business.api"

export const App = () => {
  const { error, isLoading: isTokenLoading } = useVerifyTokenQuery("")
  const [isOffline, setIsOffline] = useState(!navigator.onLine)

  const location = useLocation()
  const navigate = useNavigate()

  const username = localStorage.getItem("firstName") ?? ""

  const urlParams = new URLSearchParams(location.search)
  const cParam = urlParams.get("c")

  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""

  const isAuthenticated = useAuth()

  const saveCParamAndRedirect = (cParam: string) => {
    sessionStorage.setItem("cParam", cParam)
    navigate(`/login?c=${cParam}`)
  }

  useEffect(() => {
    // Handle the "c" parameter logic
    if (cParam !== null && cParam !== undefined) {
      if (isAuthenticated) {
        // Redirect to purchase-fills page
        navigate(`/licensing/purchase-fills?c=${cParam}`)
      } else {
        // Save the "c" parameter and redirect to login
        saveCParamAndRedirect(cParam)
      }
    } else if (!isAuthenticated) {
      // Check for a saved "c" parameter and redirect to login
      const savedCParam = sessionStorage.getItem("cParam")
      if (savedCParam !== null && savedCParam !== undefined) {
        saveCParamAndRedirect(savedCParam)
      }
    }
  }, [cParam, isAuthenticated, navigate])

  // GA4 Analytics
  useEffect(() => {
    initAnalytics()
    trackPageView(location.pathname + location.search)
    trackEvent("User Authentication", "login", username)
  }, [location])

  useEffect(() => {
    if (isAuthenticated && location.pathname === "/login") {
      navigate("/")
    }
  }, [location, navigate])

  useEffect(() => {
    if (!isTokenLoading && !isPasswordResetPage(location) && (error as any)?.status === 401) {
      // localStorage.clear()
      localStorage.removeItem("token")
      localStorage.removeItem("firstName")
      localStorage.removeItem("lastName")
      localStorage.removeItem("locations")
      localStorage.removeItem("licensing")
      localStorage.removeItem("licensingSupport")
      navigate("/login")
    }
  }, [isTokenLoading, error])

  const { refetch } = useGetNewArticlesQuery(
    {},
    {
      skip: !isAuthenticated,
    }
  )
  // scroll to the top automatically on every page and refetch the latest data
  useEffect(() => {
    window.scrollTo(0, 0)
    if (isAuthenticated) {
      refetch().catch(console.error)
    }

    return () => {
      toast.dismiss("loadingToast")
      if (isAuthenticated) {
        localStorage.setItem("getLatest", JSON.stringify(null))
      }
    }
  }, [location.pathname])

  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false)
    }
    const handleOffline = () => {
      setIsOffline(true)
    }

    window.addEventListener("online", handleOnline)
    window.addEventListener("offline", handleOffline)

    return () => {
      window.removeEventListener("online", handleOnline)
      window.removeEventListener("offline", handleOffline)
    }
  }, [isOffline])

  // we need this useEffect to initialize our navigation service for error boundary since we can't use hooks outside the component cycle
  useEffect(() => {
    setNavigateFunction(navigate)
  }, [navigate])

  if (isTokenLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress color="primary" size={50} />
      </div>
    )
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
      <ThemeProvider theme={customTheme}>
        <OfflineBackdrop isOffline={isOffline} />
        {isAuthenticated && <ActivityMonitoring />}
        <RoutesContainer />
        <Toaster />
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  )
}
