import { useState, useEffect, useCallback, useRef } from "react"
import dayjs, { type Dayjs } from "dayjs"
import { useGetPerformanceMutation } from "../../../redux/business/business.api"
import { PerformanceReport } from "../../../components/Reports/Performance"
import { ReportSkeleton } from "../../../layouts/Skeletons/ReportSkeleton"
import { useMediaQuery, useTheme } from "@mui/material"
import Alert, { type AlertColor } from "@mui/material/Alert"
import { resetFilters } from "../../../redux/filters/filters.slice"
import { useDispatch, useSelector } from "react-redux"
import { type HandleSnackBarType, LocationInputType } from "../../../types/reportTypes"
import { ReportLayout } from "../../../layouts/ReportLayout"
import { getReportsHandler } from "../../../utils/ReportHandlerInput"
import { CalendarInputType } from "../../../types/reportTypes"
import { useNavigate, useSearchParams } from "react-router-dom"
import { flattenExport } from "../../../middlewares/flattenExport"
import { performanceDetailedConfig, performanceSummaryConfig } from "../../../data/exportConfigs"
import { type Business, type Location } from "../../../types/performanceTypes"
import { type RootState } from "../../../redux/store"
// import { EncryptStorage } from "encrypt-storage"
// const storage = new EncryptStorage("jfkejfklrjklgjerlk485859809gjtkljkgjl")

interface Props {
  openFiltersHandler?: () => void
}

export function Performance(props: Props) {
  const [fetchReport] = useGetPerformanceMutation()
  const [location, setLocation] = useState<string | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [showHeaderDate, setShowHeaderDate] = useState(false)
  const hasHeaderDate = true
  const requiresAPICall = true
  const [reportDateRange, setReportDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs(),
    dayjs(),
  ])
  const [headerDateRange, setHeaderDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null])
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("error")
  const [report, setReport] = useState([])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  const [showForm, setShowForm] = useState(isMobile)
  const [isFetchingReport, setIsFetchingReport] = useState(false)
  const [firstSelectedValue, setFirstSelectedValue] = useState<any>(null)
  const [searchParams] = useSearchParams()
  const allLocationsOption = { name: "All Locations", id: null, businessId: null }
  const [selectedValue, setSelectedValue] = useState<any>(allLocationsOption)
  const isMountedRef = useRef(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isMobile) {
      setShowForm(true)
    }
  }, [isMobile])

  useEffect(() => {
    // When the component mounts
    isMountedRef.current = true

    return () => {
      // When the component unmounts
      isMountedRef.current = false
      dispatch(resetFilters())
    }
  }, [])

  const toggleForm = useCallback(
    (show: boolean) => {
      if (!isMobile) {
        setShowForm(true)
      } else {
        setShowForm(show)
      }
    },
    [isMobile]
  )

  const handleLocationChange = (e: React.SyntheticEvent<Element, Event>, value: number | null) => {
    setLocation(value !== null ? value.toString() : null)
  }

  const handleSetSelectedValue = (value: any | null) => {
    setSelectedValue(value)
    if (firstSelectedValue === null) {
      setFirstSelectedValue(value)
    }
  }

  const handleSnackBar: HandleSnackBarType = (isOpen, severity, message) => {
    setSnackbarSeverity(severity)
    setSnackbarMessage(message)
    setSnackbarOpen(isOpen)
  }

  useEffect(() => {
    const handleOrientationChange = () => {
      // Explicitly check if flattenedReport and its stores property are not null or undefined.
      if (report !== null && report !== undefined && Object.keys(report ?? {}).length > 0) {
        if (Object.keys(report).length > 0) {
          setShowForm(false)
        }
      }
    }

    window.addEventListener("orientationchange", handleOrientationChange)

    // Cleanup the event listener when the component unmounts or the report changes.
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange)
    }
  }, [report])

  useEffect(() => {
    if (isMobile && report.length !== 0) {
      toggleForm(false)
    }
  }, [report, isMobile, report?.length, toggleForm])

  // this useEffect allows us to navigate through browser back and forward button
  // uncomment the code below for browser back and forward button

  useEffect(() => {
    if (!searchParams.has("locationId")) {
      return
    }
    const startDateParam = searchParams.get("startDate")
    const endDateParam = searchParams.get("endDate")

    const startDate =
      startDateParam !== null && startDateParam !== ""
        ? dayjs(startDateParam)
        : dayjs().startOf("day")
    const endDate =
      endDateParam !== null && endDateParam !== "" ? dayjs(endDateParam) : dayjs().endOf("day")

    // Fetch the locationId from URL search parameters
    const locationId = searchParams.get("locationId") ?? ""
    const locationName = searchParams.get("locationName")
    const businessId = searchParams.get("businessId")

    // Determine the selected value based on the presence of locationId
    const newSelectedValue =
      locationId === ""
        ? allLocationsOption
        : {
            name: locationName,
            id: locationId,
            businessId,
          }

    setLocation(newSelectedValue.id)
    setSelectedValue(newSelectedValue)
    setFirstSelectedValue(newSelectedValue)
    setReportDateRange([startDate, endDate])
    setHeaderDateRange([startDate, endDate])
    getReportsHandler(
      fetchReport,
      setIsFetchingReport,
      handleSnackBar,
      setReport,
      newSelectedValue.id,
      hasHeaderDate,
      requiresAPICall,
      setShowHeaderDate,
      "data.businesses",
      {
        businessId: newSelectedValue.businessId,
        startDate,
        endDate,
      },
      isMountedRef
    ).catch((error) => {
      console.log(error)
    })
  }, [])
  const productFilters = useSelector((state: RootState) => state.filters.productFilters)
  const statusFilters = useSelector((state: RootState) => state.filters.statusFilters)

  const filterLocations = (locations: Location[]): Location[] => {
    const noProductFiltersSelected = Object.values(productFilters).every((value) => !value)
    const noStatusFiltersSelected = Object.values(statusFilters).every((value) => !value)

    if (noProductFiltersSelected && noStatusFiltersSelected) {
      return locations
    }

    return locations.filter((location) => {
      // Product Filters
      const productFilterKey = Object.keys(productFilters).find(
        (key) => key.toLowerCase() === location.productType.toLowerCase()
      )

      let productFilterValue = true
      if (productFilterKey !== undefined) {
        productFilterValue = productFilters[productFilterKey]
      }

      // Status Filters
      const statusFilterKey = Object.keys(statusFilters).find(
        (key) => key.toLowerCase() === location.statusMsg.toLowerCase()
      )

      let statusFilterValue = true
      if (statusFilterKey !== undefined) {
        statusFilterValue = statusFilters[statusFilterKey]
      }

      const productFilterPass = noProductFiltersSelected || productFilterValue
      const statusFilterPass = noStatusFiltersSelected || statusFilterValue

      return productFilterPass && statusFilterPass
    })
  }

  const filterBusinesses = (businesses: Business[]): Business[] => {
    let filteredBusinesses = businesses
      .map((business) => ({
        ...business,
        locations: filterLocations(business.locations),
      }))
      .filter((business) => business.locations.length > 0)

    filteredBusinesses = filteredBusinesses.map((business) => {
      const summary = business.locations.reduce(
        (prev, location) => {
          return {
            play: prev.play + location.play,
            win: prev.win + location.win,
            net: prev.net + location.net,
          }
        },
        { play: 0, win: 0, net: 0 }
      )

      business.play = summary.play
      business.win = summary.win
      business.net = summary.net

      return business
    })

    return filteredBusinesses
  }

  const filteredReport = filterBusinesses(report)
  const hasBusinesses = filteredReport.length > 0
  const exportFlattenedDetailedData =
    Array.isArray(filteredReport) && filteredReport.length > 0
      ? flattenExport(filteredReport, performanceDetailedConfig)
      : []

  const exportFlattenedSummaryData =
    Array.isArray(filteredReport) && filteredReport.length > 0
      ? flattenExport(filteredReport, performanceSummaryConfig)
      : []
  return (
    <ReportLayout
      hasExport={true}
      exportFlattenedDetailedData={exportFlattenedDetailedData}
      exportFlattenedSummaryData={exportFlattenedSummaryData}
      reportName={"Performance"}
      calendarInput={CalendarInputType.DateRange}
      locationInput={LocationInputType.SelectGetLoad}
      showHeaderDate={showHeaderDate}
      hasHeaderDate={hasHeaderDate}
      headerDateRange={headerDateRange}
      showForm={showForm}
      accordionHeadings={["Name", "Play", "Win", "Net"]}
      showAccordionHeadings={hasBusinesses}
      toggleForm={toggleForm}
      handleLocationChange={handleLocationChange}
      selectedValue={selectedValue}
      handleSetSelectedValue={handleSetSelectedValue}
      setReportDateRange={setReportDateRange}
      openFiltersHandler={props.openFiltersHandler}
      location={location}
      getReportHandler={async () => {
        if (selectedValue.id === null) {
          // Create a new URLSearchParams object with the current search params
          const newSearchParams = new URLSearchParams(searchParams.toString())

          // Delete the parameters you want to clear
          newSearchParams.set("locationId", "")
          newSearchParams.set(
            "startDate",
            reportDateRange[0]?.startOf("day").format("YYYY-MM-DDTHH:mm:ss") ?? ""
          )
          newSearchParams.set(
            "endDate",
            reportDateRange[1]?.endOf("day").format("YYYY-MM-DDTHH:mm:ss") ?? ""
          )
          newSearchParams.delete("businessId")
          newSearchParams.delete("locationName")

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        } else if (selectedValue.id !== null && selectedValue.id !== undefined) {
          // Create a new URLSearchParams object with the current search params
          const newSearchParams = new URLSearchParams(searchParams.toString())

          // Set the search params as you had before
          newSearchParams.set("locationId", selectedValue.id)
          newSearchParams.set("businessId", selectedValue.businessId)
          newSearchParams.set("locationName", selectedValue.name)
          newSearchParams.set(
            "startDate",
            reportDateRange[0]?.startOf("day").format("YYYY-MM-DDTHH:mm:ss") ?? ""
          )
          newSearchParams.set(
            "endDate",
            reportDateRange[1]?.endOf("day").format("YYYY-MM-DDTHH:mm:ss") ?? ""
          )

          // Use the 'navigate' method with the 'replace' option
          navigate(`?${newSearchParams.toString()}`, { replace: true })
        }
        try {
          await getReportsHandler(
            fetchReport,
            setIsFetchingReport,
            handleSnackBar,
            setReport,
            location,
            hasHeaderDate,
            requiresAPICall,
            setShowHeaderDate,
            "data.businesses",
            {
              businessId: selectedValue.businessId,
              startDate: reportDateRange[0]?.startOf("day").format("YYYY-MM-DDTHH:mm:ss"), // Set startDate to 00:00:00 o'clock
              endDate: reportDateRange[1]?.endOf("day").format("YYYY-MM-DDTHH:mm:ss"), // Set endDate to the current time
            },
            isMountedRef
          )

          setHeaderDateRange([reportDateRange[0], reportDateRange[1]])
          setFirstSelectedValue(selectedValue)
        } catch (error) {
          console.log(error)
        }
      }}
    >
      <>
        {isFetchingReport && <ReportSkeleton />}
        {!isFetchingReport && report?.length === 0 && snackbarOpen && (
          <Alert severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarMessage}
          </Alert>
        )}
        {!isFetchingReport && report?.length > 0 && (
          <PerformanceReport hasBusinesses={hasBusinesses} report={filteredReport} />
        )}
      </>
    </ReportLayout>
  )
}
