// React imports
import { useState, useEffect, memo, useRef } from "react"

// Redux hooks for state management
import { useSelector } from "react-redux"

// React Router hooks for navigation
import { useLocation, useNavigate } from "react-router-dom"

// Material-UI components, sorted alphabetically
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"

// Material-UI icons, sorted alphabetically
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded"
import MoreVertIcon from "@mui/icons-material/MoreVert"

// Custom components
import { SelectableTypography } from "../../SelectableTypography"
import { StatusCircleIcon } from "../../StatusCircleIcon"
import { ThreeSixtyMenu } from "../../ThreeSixtyMenu"

// Styled components or custom styled Material-UI components
import CardHeader from "@mui/material/CardHeader"
import {
  CardContentStyled,
  CardStyled,
  ExpandMore,
  ListItemStyled,
  ListItemTextStyled,
  ListItemTextStyledSmall,
  StyledBadge,
} from "../../../styles/PeriodCardStyles"
import {
  PeriodStyledTableCell,
  PeriodStyledTableHeadCell,
  PeriodStyledTableRow,
} from "../../../styles/AccordionStyles"

// Types
import type { IBusiness, ILocations } from "./PeriodTypesFlattened"
import { type RootState } from "../../../redux/store"

// Utility functions
import { formatDate } from "../../../utils/formatDate"
import { formatAmount, formatNegative } from "../../../utils/formatAmount"
import { getOptions } from "../../../data/menuOptions"

// Assets
import terminalIcon from "../../../assets/terminals/terminal-icon.svg"

interface PeriodItemProps {
  business: IBusiness
}

const PeriodItemComponent = ({ business }: PeriodItemProps) => {
  const [locallyExpanded, setLocallyExpanded] = useState<Record<string, boolean>>({})
  const [manuallyClosed, setManuallyClosed] = useState<Record<string, boolean>>({})
  const [selectedStores, setSelectedStores] = useState<Record<string, ILocations>>({})
  const selectedStore = selectedStores[business.businessInfo.businessId]
  const { expandAll } = useSelector((state: RootState) => state.buttons)
  const [anchorEls, setAnchorEls] = useState<Record<string, HTMLElement | null>>({})
  const businessId360 = useRef<string>("")
  const businessName360 = useRef<string>("")
  const locationId360 = useRef<string>("")
  const locationName360 = useRef<string>("")

  const isExpanded = expandAll
    ? !(manuallyClosed[business.businessInfo.businessId] ?? false)
    : locallyExpanded[business.businessInfo.businessId] ?? false

  const getNetColor = (amount: number) => {
    return amount < 0 ? "red" : "inherit"
  }

  const navigate = useNavigate()
  const location = useLocation()
  const handleClose = (storeId: string) => {
    setAnchorEls({ ...anchorEls, [storeId]: null })
  }

  const handleBussinesClick = (event: React.MouseEvent<HTMLButtonElement>, business: IBusiness) => {
    localStorage.setItem("option", "false")
    businessId360.current = business.businessInfo.businessId
    businessName360.current = business.businessInfo.name
    locationId360.current = business.stores[0].store.id
    locationName360.current = business.stores[0].store.name
    setAnchorEls({ ...anchorEls, [business.businessInfo.businessId]: event.currentTarget })
  }

  const handleStoreClick = (event: React.MouseEvent<HTMLButtonElement>, storeItem: ILocations) => {
    localStorage.setItem("option", "true")
    businessId360.current = business.businessInfo.businessId
    businessName360.current = business.businessInfo.name
    locationId360.current = storeItem.store.id
    locationName360.current = storeItem.store.name
    setAnchorEls({ ...anchorEls, [locationId360.current]: event.currentTarget })
  }

  useEffect(() => {
    if (!expandAll) {
      setManuallyClosed({})
    } else {
      setLocallyExpanded({})
    }
  }, [expandAll])

  useEffect(() => {
    if (!isExpanded) {
      setSelectedStores({})
    }
  }, [isExpanded])

  const handleExpandClick = () => {
    if (expandAll) {
      setManuallyClosed({
        ...manuallyClosed,
        [business.businessInfo.businessId]: !manuallyClosed[business.businessInfo.businessId],
      })
    } else {
      setLocallyExpanded({
        ...locallyExpanded,
        [business.businessInfo.businessId]: !locallyExpanded[business.businessInfo.businessId],
      })
    }
  }

  const handleListItemClick = (businessId: string, storeItem: ILocations) => {
    const selectedStore = selectedStores[businessId]
    if (selectedStore !== undefined && selectedStore.store.id === storeItem.store.id) {
      const newSelectedStores = Object.fromEntries(
        Object.entries(selectedStores).filter(([key]) => key !== businessId)
      )
      setSelectedStores(newSelectedStores)
    } else {
      setSelectedStores({ ...selectedStores, [businessId]: storeItem })
    }
  }

  const handleCloseBusiness = () => {
    handleClose(business.businessInfo.businessId)
  }

  const businessOptions = getOptions(
    handleCloseBusiness,
    navigate,
    businessId360.current,
    businessName360.current,
    locationId360.current,
    locationName360.current,
    location
  )

  return (
    <CardStyled variant="outlined">
      <CardHeader
        sx={{
          marginX: 0,
          marginBottom: 0,
          paddingX: 1,
          paddingY: { xs: 0.5, sm: 1 },
          borderRadius: "1px",
          backgroundColor: "#DEE2E6",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 3,
            }}
          >
            <StyledBadge
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              color="primary"
              badgeContent={business.stores.length > 1 ? business.stores.length : null}
              max={9}
            >
              <ExpandMore
                expand={isExpanded}
                onClick={handleExpandClick}
                aria-expanded={isExpanded}
                aria-label="show more"
              >
                <NavigateNextRoundedIcon />
              </ExpandMore>
            </StyledBadge>
            <Typography variant="h6" fontWeight="bold">
              {business.businessInfo.name}
            </Typography>
          </Box>
        }
        action={
          <>
            <IconButton
              onClick={(event) => {
                handleBussinesClick(event, business)
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <ThreeSixtyMenu
              id={`business-menu-${business.businessInfo.businessId}`}
              anchorEl={anchorEls[business.businessInfo.businessId]}
              onClose={() => {
                handleClose(business.businessInfo.businessId)
              }}
              options={businessOptions}
            />
          </>
        }
      />

      <Box display="flex" flexDirection="column" height="100%">
        <CardContentStyled>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <List dense sx={{ paddingTop: 0 }}>
              {business.stores.map((storeItem: ILocations) => {
                const handleCloseStore = () => {
                  handleClose(storeItem.store.id)
                }
                const storeOptions = getOptions(
                  handleCloseStore,
                  navigate,
                  businessId360.current,
                  businessName360.current,
                  locationId360.current,
                  locationName360.current,
                  location
                )
                return (
                  <ListItemStyled key={storeItem.store.id}>
                    <ListItemButton
                      selected={
                        selectedStore !== null && selectedStore?.store.id === storeItem.store.id
                      }
                      onClick={() => {
                        handleListItemClick(business.businessInfo.businessId, storeItem)
                      }}
                      sx={{ paddingLeft: 2.5, paddingRight: 0 }}
                    >
                      <ListItemTextStyled
                        primary={
                          <Box display="flex" alignItems="center">
                            <span style={{ marginTop: "-3px" }}>
                              <StatusCircleIcon status={storeItem.store.statusMsg} size={14} />
                            </span>
                            <SelectableTypography
                              selected={
                                selectedStore !== null &&
                                selectedStore?.store.id === storeItem.store.id
                              }
                            >
                              {storeItem.store.id} {storeItem.store.sofwareName}
                            </SelectableTypography>
                          </Box>
                        }
                      />
                      <ListItemTextStyledSmall
                        primary={
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Tooltip placement="left" title={"Terminals"}>
                              <img
                                src={terminalIcon}
                                alt="terminal icon"
                                style={{ marginRight: "10px" }}
                              />
                            </Tooltip>
                            <SelectableTypography
                              selected={
                                selectedStore !== null &&
                                selectedStore?.store.id === storeItem.store.id
                              }
                            >
                              {storeItem.store.terminalCount}
                            </SelectableTypography>
                          </div>
                        }
                      />
                      <ListItemTextStyled
                        primary={
                          <div className="flex flex-wrap">
                            <SelectableTypography
                              className="pr-1"
                              selected={
                                selectedStore !== null &&
                                selectedStore?.store.id === storeItem.store.id
                              }
                            >
                              {formatDate(storeItem.lastUpdate, true).formattedDate}
                            </SelectableTypography>
                            <SelectableTypography
                              selected={
                                selectedStore !== null &&
                                selectedStore?.store.id === storeItem.store.id
                              }
                            >
                              {formatDate(storeItem.lastUpdate, true).formattedTime}
                            </SelectableTypography>
                          </div>
                        }
                      />
                    </ListItemButton>
                    <IconButton
                      onClick={(event) => {
                        handleStoreClick(event, storeItem)
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <ThreeSixtyMenu
                      id={`store-menu-${storeItem.store.id}`}
                      anchorEl={anchorEls[storeItem.store.id]}
                      onClose={() => {
                        handleClose(storeItem.store.id)
                      }}
                      options={storeOptions}
                    />
                  </ListItemStyled>
                )
              })}
            </List>
          </Collapse>
        </CardContentStyled>
        <TableContainer component={Paper}>
          <Table sx={{ tableLayout: "fixed" }} size="small">
            <TableHead>
              <PeriodStyledTableRow>
                <PeriodStyledTableHeadCell>Period</PeriodStyledTableHeadCell>
                <PeriodStyledTableHeadCell>Sales</PeriodStyledTableHeadCell>
                <PeriodStyledTableHeadCell>Redeem</PeriodStyledTableHeadCell>
                <PeriodStyledTableHeadCell>Net</PeriodStyledTableHeadCell>
              </PeriodStyledTableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <PeriodStyledTableCell>Today</PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    {
                      formatAmount(selectedStore?.summary?.cds ?? business.summaryTotals.cds ?? 0)
                        .full
                    }
                  </span>
                  <span className="block sm:hidden">
                    {
                      formatAmount(selectedStore?.summary?.cds ?? business.summaryTotals.cds ?? 0)
                        .short
                    }
                  </span>
                </PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    {
                      formatAmount(selectedStore?.summary?.cdr ?? business.summaryTotals.cdr ?? 0)
                        .full
                    }
                  </span>
                  <span className="block sm:hidden">
                    {
                      formatAmount(selectedStore?.summary?.cdr ?? business.summaryTotals.cdr ?? 0)
                        .short
                    }
                  </span>
                </PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    <Typography
                      fontSize="inherit"
                      color={getNetColor(selectedStore?.summary?.tmn ?? business.summaryTotals.cdn)}
                    >
                      {
                        formatNegative(
                          selectedStore?.summary?.cdn ?? business.summaryTotals.cdn ?? 0
                        ).full
                      }
                    </Typography>
                  </span>
                  <span className="block sm:hidden">
                    <Typography
                      fontSize="inherit"
                      color={getNetColor(selectedStore?.summary?.tmn ?? business.summaryTotals.cdn)}
                    >
                      {
                        formatNegative(
                          selectedStore?.summary?.cdn ?? business.summaryTotals.cdn ?? 0
                        ).short
                      }
                    </Typography>
                  </span>
                </PeriodStyledTableCell>
              </TableRow>
              <TableRow>
                <PeriodStyledTableCell>This Week</PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    {
                      formatAmount(selectedStore?.summary?.cws ?? business.summaryTotals.cws ?? 0)
                        .full
                    }
                  </span>
                  <span className="block sm:hidden">
                    {
                      formatAmount(selectedStore?.summary?.cws ?? business.summaryTotals.cws ?? 0)
                        .short
                    }
                  </span>
                </PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    {
                      formatAmount(selectedStore?.summary?.cwr ?? business.summaryTotals.cwr ?? 0)
                        .full
                    }
                  </span>
                  <span className="block sm:hidden">
                    {
                      formatAmount(selectedStore?.summary?.cwr ?? business.summaryTotals.cwr ?? 0)
                        .short
                    }
                  </span>
                </PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    <Typography
                      fontSize="inherit"
                      color={getNetColor(selectedStore?.summary?.tmn ?? business.summaryTotals.cwn)}
                    >
                      {
                        formatNegative(
                          selectedStore?.summary?.cwn ?? business.summaryTotals.cwn ?? 0
                        ).full
                      }
                    </Typography>
                  </span>
                  <span className="block sm:hidden">
                    <Typography
                      fontSize="inherit"
                      color={getNetColor(selectedStore?.summary?.tmn ?? business.summaryTotals.cwn)}
                    >
                      {
                        formatNegative(
                          selectedStore?.summary?.cwn ?? business.summaryTotals.cwn ?? 0
                        ).short
                      }
                    </Typography>
                  </span>
                </PeriodStyledTableCell>
              </TableRow>
              <TableRow>
                <PeriodStyledTableCell>Last Week</PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    {
                      formatAmount(selectedStore?.summary?.lws ?? business.summaryTotals.lws ?? 0)
                        .full
                    }
                  </span>
                  <span className="block sm:hidden">
                    {
                      formatAmount(selectedStore?.summary?.lws ?? business.summaryTotals.lws ?? 0)
                        .short
                    }
                  </span>
                </PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    {
                      formatAmount(selectedStore?.summary?.lwr ?? business.summaryTotals.lwr ?? 0)
                        .full
                    }
                  </span>
                  <span className="block sm:hidden">
                    {
                      formatAmount(selectedStore?.summary?.lwr ?? business.summaryTotals.lwr ?? 0)
                        .short
                    }
                  </span>
                </PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    <Typography
                      fontSize="inherit"
                      color={getNetColor(selectedStore?.summary?.tmn ?? business.summaryTotals.lwn)}
                    >
                      {
                        formatNegative(
                          selectedStore?.summary?.lwn ?? business.summaryTotals.lwn ?? 0
                        ).full
                      }
                    </Typography>
                  </span>
                  <span className="block sm:hidden">
                    <Typography
                      fontSize="inherit"
                      color={getNetColor(selectedStore?.summary?.tmn ?? business.summaryTotals.lwn)}
                    >
                      {
                        formatNegative(
                          selectedStore?.summary?.lwn ?? business.summaryTotals.lwn ?? 0
                        ).short
                      }
                    </Typography>
                  </span>
                </PeriodStyledTableCell>
              </TableRow>
              <TableRow>
                <PeriodStyledTableCell>This Month</PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    {
                      formatAmount(selectedStore?.summary?.tms ?? business.summaryTotals.tms ?? 0)
                        .full
                    }
                  </span>
                  <span className="block sm:hidden">
                    {
                      formatAmount(selectedStore?.summary?.tms ?? business.summaryTotals.tms ?? 0)
                        .short
                    }
                  </span>
                </PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    {
                      formatAmount(selectedStore?.summary?.tmr ?? business.summaryTotals.tmr ?? 0)
                        .full
                    }
                  </span>
                  <span className="block sm:hidden">
                    {
                      formatAmount(selectedStore?.summary?.tmr ?? business.summaryTotals.tmr ?? 0)
                        .short
                    }
                  </span>
                </PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    <Typography
                      fontSize="inherit"
                      color={getNetColor(selectedStore?.summary?.tmn ?? business.summaryTotals.tmn)}
                    >
                      {
                        formatNegative(
                          selectedStore?.summary?.tmn ?? business.summaryTotals.tmn ?? 0
                        ).full
                      }
                    </Typography>
                  </span>
                  <span className="block sm:hidden">
                    <Typography
                      fontSize="inherit"
                      color={getNetColor(selectedStore?.summary?.tmn ?? business.summaryTotals.tmn)}
                    >
                      {
                        formatNegative(
                          selectedStore?.summary?.tmn ?? business.summaryTotals.tmn ?? 0
                        ).short
                      }
                    </Typography>
                  </span>
                </PeriodStyledTableCell>
              </TableRow>
              <TableRow>
                <PeriodStyledTableCell>Last Month</PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    {
                      formatAmount(selectedStore?.summary?.lms ?? business.summaryTotals.lms ?? 0)
                        .full
                    }
                  </span>
                  <span className="block sm:hidden">
                    {
                      formatAmount(selectedStore?.summary?.lms ?? business.summaryTotals.lms ?? 0)
                        .short
                    }
                  </span>
                </PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    {
                      formatAmount(selectedStore?.summary?.lmr ?? business.summaryTotals.lmr ?? 0)
                        .full
                    }
                  </span>
                  <span className="block sm:hidden">
                    {
                      formatAmount(selectedStore?.summary?.lmr ?? business.summaryTotals.lmr ?? 0)
                        .short
                    }
                  </span>
                </PeriodStyledTableCell>
                <PeriodStyledTableCell>
                  <span className="hidden sm:block">
                    <Typography
                      fontSize="inherit"
                      color={getNetColor(selectedStore?.summary?.lmn ?? business.summaryTotals.lmn)}
                    >
                      {
                        formatNegative(
                          selectedStore?.summary?.lmn ?? business.summaryTotals.lmn ?? 0
                        ).full
                      }
                    </Typography>
                  </span>
                  <span className="block sm:hidden">
                    <Typography
                      fontSize="inherit"
                      color={getNetColor(selectedStore?.summary?.lmn ?? business.summaryTotals.lmn)}
                    >
                      {
                        formatNegative(
                          selectedStore?.summary?.lmn ?? business.summaryTotals.lmn ?? 0
                        ).short
                      }
                    </Typography>
                  </span>
                </PeriodStyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </CardStyled>
  )
}
export const BusinessCard = memo(PeriodItemComponent)
