import { useMemo } from "react"
import {
  useGetAllMachinesQuery,
  useGetFillPricingQuery,
} from "../../../redux/business/business.api"
import LicenseSupportForm from "../../../components/Licensing/LicenseSupport/LicenseSupportForm"
import LicenseSupportDialog from "../../../components/Licensing/LicenseSupport/LicenseSupportDialog"
import LicenseSupportSkeleton from "../../../layouts/Skeletons/LicenseSupportSkeleton"
import { LicenseSupportProvider } from "../../../context/LicenseSupportContext"

export function LicenseSupport() {
  const { data: machinesData, isLoading: isMachinesLoading } = useGetAllMachinesQuery(null)
  const { data: pricingData, isLoading: isPricingLoading } = useGetFillPricingQuery(null)

  const machines = useMemo(() => machinesData?.data?.machines ?? [], [machinesData])
  const fillPricingData = useMemo(() => pricingData?.data?.fillPricing ?? {}, [pricingData])

  if (isMachinesLoading || isPricingLoading) {
    return <LicenseSupportSkeleton numCards={3} />
  }

  return (
    <LicenseSupportProvider machines={machines} fillPricingData={fillPricingData}>
      <div className="w-full sm:max-w-4xl mx-auto mb-20">
        <LicenseSupportForm />
        <LicenseSupportDialog />
      </div>
    </LicenseSupportProvider>
  )
}

export default LicenseSupport
