// src/hooks/useFillActions.tsx
import { useState } from "react"
import type { CartItem } from "../types/cartItemTypes"
import {
  useRemoveCartItemMutation,
  useUpdateCartItemMutation,
} from "../redux/business/business.api"
import { parseDollarString } from "../utils/parseDollarString"
import { formatLicensingErrorMessages } from "../utils/formatLicensingErrorMessages"

interface MachineHook {
  cartItem: CartItem[]
  handleDeleteFill: (index: number) => Promise<void>
  addCartItem: (newCartItem: CartItem[], replace: boolean) => void
  fillActionsLoading: boolean
  setFillActionsLoading: React.Dispatch<React.SetStateAction<boolean>>
  handleDropdownChange: (index: number, selectedAmount: string, newPrice: number) => Promise<void>
}

function useFillActions(initialCartItem: CartItem[]): MachineHook {
  const [updateCartItem] = useUpdateCartItemMutation({})
  const [removeCartItem] = useRemoveCartItemMutation({})

  const [cartItem, setCartItem] = useState<CartItem[]>(initialCartItem)
  const [fillActionsLoading, setFillActionsLoading] = useState(false)

  const handleDeleteFill = async (index: number) => {
    setFillActionsLoading(true)
    const machineToRemove = cartItem[index]

    console.log("machineToRemove", machineToRemove)

    const removeData = {
      cartItemId: machineToRemove.cartItemId,
    }

    try {
      const res = await removeCartItem(removeData).unwrap()
      console.log("removeData", removeData)

      if (res?.rc === 1000) {
        setCartItem((currentMachines) => currentMachines.filter((_, i) => i !== index))
      } else {
        console.error("Remove API error:", res)
      }
    } catch (err) {
      console.error("Error removing cart item:", err)
    } finally {
      setFillActionsLoading(false)
    }
  }

  const addCartItem = (newCartItems: CartItem[], replace = false) => {
    setCartItem((prevMachines) => {
      if (replace) {
        return [...newCartItems]
      } else {
        const uniquePrevMachines = prevMachines.filter(
          (prevItem) => !newCartItems.some((newItem) => newItem.cartItemId === prevItem.cartItemId)
        )
        return [...newCartItems, ...uniquePrevMachines]
      }
    })
  }

  const handleDropdownChange = async (index: number, selectedAmount: string, newPrice: number) => {
    setFillActionsLoading(true)
    const cartItemToUpdate = cartItem[index]
    console.log("selectedAmount useFillActions hook", selectedAmount)

    const updateData = {
      machineId: cartItemToUpdate.machineId,
      amount: parseDollarString(selectedAmount),
      price: newPrice,
      cartItemId: cartItemToUpdate.cartItemId,
    }

    try {
      const res = await updateCartItem(updateData).unwrap()
      if (res?.rc === 1000) {
        setCartItem((prevCartItems) => {
          const updatedCartItems = [...prevCartItems]
          updatedCartItems[index] = {
            ...updatedCartItems[index],
            price: newPrice,
            amount: parseDollarString(selectedAmount),
          }
          return updatedCartItems
        })
      } else if (res.rc === 2015 || res.rc === 2021) {
        const messages = formatLicensingErrorMessages(res)
        messages.forEach((msg) => {
          console.error("UpdateCart API error:", msg)
        })
        throw new Error(messages.join("; "))
      } else {
        console.error("Unexpected update response:", res)
      }
    } catch (err: any) {
      let messages: string[] = []
      if (err?.data != null) {
        messages = formatLicensingErrorMessages(err.data)
      } else if (err instanceof Error) {
        messages = [err.message]
      } else {
        messages = ["An unexpected error occurred while updating the cart."]
      }
      messages.forEach((msg) => {
        console.error("Error updating cart item:", msg)
      })
      throw new Error(messages.join("; "))
    } finally {
      setFillActionsLoading(false)
    }
  }

  return {
    cartItem,
    handleDeleteFill,
    addCartItem,
    fillActionsLoading,
    setFillActionsLoading,
    handleDropdownChange,
  }
}

export default useFillActions
