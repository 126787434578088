import React from "react"

const LicenseSupportHeader: React.FC = () => {
  return (
    <div className="flex justify-between items-center py-3 px-4 bg-[#14213D]">
      <div className="flex-grow text-center">
        <h1 className="text-2xl font-bold text-white">License Support</h1>
      </div>
    </div>
  )
}

export default LicenseSupportHeader
